$j(document).ready(function() {

    var modeSwitchSelectSelector = '.field-name-field-gallery-item-mode select';

    function refreshStatesOfGalleryFields() {
        $j( modeSwitchSelectSelector ).each(function() {
            var containerSelector = $j(this).closest('tr');
            var mode = $j(this).val();
            switch (mode) {
                default:
                case 'image':
                    $j(containerSelector).find(".field-name-field-gallery-item-picture").show();
                    $j(containerSelector).find(".field-name-field-gallery-item-youtube").hide();
                    $j(containerSelector).find(".field-name-field-gallery-item-thumbnail").hide();
                    break;
                case 'youtube':
                    $j(containerSelector).find(".field-name-field-gallery-item-picture").hide();
                    $j(containerSelector).find(".field-name-field-gallery-item-youtube").show();
                    $j(containerSelector).find(".field-name-field-gallery-item-thumbnail").show();
                    break;
            }
        });
    }

    refreshStatesOfGalleryFields();
    $j(document).bind('DOMNodeInserted', function(e) {
        refreshStatesOfGalleryFields();
    });
    $j(document).on('change', modeSwitchSelectSelector, function(){
        refreshStatesOfGalleryFields();
    });

});
