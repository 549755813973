(function ($) {
    $(document).ready(function () {

        if ($("div#mainSlider").length > 0) {
            var mainSlider = $("div#mainSlider").sliderTabs({
                autoplay: 15000,
                mousewheel: false,
                position: "bottom",
                panelArrows: true,
                panelArrowsShowOnHover: true,
                tabArrows: true,
                tabHeight: 45,
                tabArrowWidth: 45,
                transition: 'fade',
            });

            $("#mainSlider").css('visibility', 'visible');
        }

        if ($(".sliderTabs.top-nav-regular").length > 0) {
            $(".sliderTabs.top-nav-regular").sliderTabs({
                autoplay: false,
                mousewheel: false,
                position: "top",
                tabHeight: 50,
                tabArrows: true
            });
        }

        if ($(".page-szukaj .result-item").length > 0) {
            $(".page-szukaj .item-gallery__slider").slick();
        }

        if ($(".page-maszyna-uzywana .product-used-gallery").length > 0) {
            $(".page-maszyna-uzywana .product-used-gallery .main-image").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                infinite: false,
                adaptiveHeight: false,
                arrows: false,
                asNavFor: '.page-maszyna-uzywana .product-used-gallery .thumbnails ul',
            });
        }
        
        
        if ($(".page-maszyna-uzywana .product-used-gallery").length > 0) {
            $(".page-maszyna-uzywana .product-used-gallery .thumbnails ul").slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                infinite: false,
                arrows: true,
                focusOnSelect: true,
                asNavFor: '.page-maszyna-uzywana .product-used-gallery .main-image',
                responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 3,
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 2,
                      }
                    }
                ]
            });
        }
        
        
        if ($(".view-products-used-featured-products .view-content").length > 0) {
            $(".view-products-used-featured-products .view-content").slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                arrows: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                responsive: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 3,
                      }
                    },
                    {
                      breakpoint: 980,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                    {
                      breakpoint: 580,
                      settings: {
                        slidesToShow: 1,
                      }
                    }
                ]
            });
        }
        
        if ($('div#mainSlider .slider-caption').length > 0) {
            setCaptionPosition();
            var tableContent = $('#mainSlider .slider-caption table');
            if (tableContent.length > 0) {
                tableContent.each(function () {
                    $(this).closest('.slider-caption').addClass('relative-content');
                });
            }

            $(window).on('resize', function () {
                setCaptionPosition();
            })
        }

        function setCaptionPosition() {
            $('div#mainSlider .slider-caption').css(
                    {
//                        'max-width': $('.footer.container').first().innerWidth(),
//                        'left': ($(window).width() - $('.footer.container').first().innerWidth()) / 2
                    }
            );

            var table = $('#mainSlider .slider-caption table');
            if (table.length > 0) {
                table.each(function () {
                    if ($j(window).width() + getScrollbarWidth() < 921) {
                        $j('.ui-slider-tab-content.selected table tbody').addClass('brand-background-color');
                    } else {
                        $j('.ui-slider-tab-content.selected table tbody').removeClass('brand-background-color');
                    }
                })
            }
        }

        function getScrollbarWidth() {
            var scrollDiv = document.createElement("div");
            scrollDiv.className = "scrollbar-measure";
            document.body.appendChild(scrollDiv);
            var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        }
    });
})(jQuery);
