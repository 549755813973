$j = jQuery.noConflict();

window.rangeSlider = function () {
};

window.rangeSlider.prototype = {
    minRange: 0,
    maxRange: 0,
    items: [],
    initialize: function (selector) {
        this.findListElements();
    },
    findListElements: function () {
        var listEl = $j('[data-range-from]');
        for (var i = 0; i < listEl.length; i++) {
            var item = $j(listEl[i]);
            this.addItem(item);
        }
    },
    addItem: function (item) {
        this.items.push(item);
    },
    toggleElementsFromRange: function (fromRange, toRange) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].attr('data-range-from') < fromRange || this.items[i].attr('data-range-to') > toRange) {
                this.items[i].fadeOut(500);
            } else {
                this.items[i].fadeIn(500);
            }
        }
    }
}


$j(document).ready(function () {
    var sliderInitializator = new rangeSlider();
    sliderInitializator.initialize();

    if ($j(".product-grid-filter #ex12c").length > 0) {
        var mySlider = $j(".product-grid-filter #ex12c").slider({
            min: 0,
            max: 500,
            range: true,
            tooltip: 'always',
            tooltip_split: true
        });
        mySlider.slider().on('change', function () {
            var from = mySlider.slider('getValue')[0];
            var to = mySlider.slider('getValue')[1];
            sliderInitializator.toggleElementsFromRange(from, to);
        });
    }
})