(function ( $ ) {

    $.fn.droplist = function(options) {

        var settings = $.extend({
            overflow: "left",
            ellipsis: "&#8230;",
            dropicon: "...",
            resizeRefElement: null
            
        }, options );
        
        this.each(function() {
            if(!$(this).data('breadcrumbsData')) {
                $(this).data('breadcrumbsData', {html: $(this).html()});
            }
            
            $(this).html($(this).data('breadcrumbsData').html);
        })
        
        this.each(function(){
            var createDropList			= false;
            var width					= $(this).width() - 50;
            var children				= $.makeArray($(this).find('> *'));
            var childrenWithWidths		= [];
            var childrenWidthTotal		= 0;
            var hiddenChildren			= [];
            
            _.each(children, function(child){
                childrenWidthTotal += $(child).outerWidth();
                childrenWithWidths.push([child, $(child).outerWidth()]);
            });
            
            if ( settings.overflow === "left" ) {

                $(this).addClass('droplist-left');

                if ( childrenWidthTotal > width ) {
                    childrenWidthTotal = 0;
                    for ( var i = childrenWithWidths.length - 1; i >= 0; i-- ) {
                        childrenWidthTotal += childrenWithWidths[i][1];
                        if ( childrenWidthTotal > width ) {
                            hiddenChildren.push(childrenWithWidths[i][0]);
                            $(childrenWithWidths[i][0]).remove();
                            createDropList = true;
                        }
                    }
                }

                if ( createDropList === true ) {

                    var html = [];

                    if ( settings.ellipsis ) {
                        $(this).prepend('<li class=\"droplist-ellipsis\">' + settings.ellipsis + '</li>');
                    }

                    $(this).prepend('<li class=\"droplist-dropdown\"><a href=\"javascript:void(0);\" class=\"droplist-toggle\" data-toggle=\"droplist\">' + settings.dropicon + '</a><ul class=\"droplist-menu\"></ul></li>');

                    _.each(hiddenChildren, function(hiddenChild){
                        html.push(hiddenChild.outerHTML);
                    })

                    $(this).find('.droplist-menu').append( html.join('') );
                }

            } else {

                $(this).addClass('droplist-right');

                if ( childrenWidthTotal > width ) {
                    childrenWidthTotal = 0;
                    for ( var i = 0; i < childrenWithWidths.length; i++ ) {
                        childrenWidthTotal += childrenWithWidths[i][1];
                        if ( childrenWidthTotal > width ) {
                            hiddenChildren.push(childrenWithWidths[i][0]);
                            $(childrenWithWidths[i][0]).remove();
                            var n = i - 1;
                            $(childrenWithWidths[n][0]).addClass('no-after');
                            createDropList = true;
                        }
                    }
                }

                if ( createDropList === true ) {

                    var html = [];

                    if ( settings.ellipsis ) {
                        $(this).append('<li class=\"droplist-ellipsis\">' + settings.ellipsis + '</li>');
                    }

                    $(this).append('<li class=\"droplist-dropdown\"><a href=\"javascript:void(0);\" class=\"droplist-toggle\" data-toggle=\"droplist\">' + settings.dropicon + '</a><ul class=\"droplist-menu\"></ul></li>');

                    _.each(hiddenChildren, function(hiddenChild){
                        html.push(hiddenChild.outerHTML);
                    })

                    $(this).find('.droplist-menu').append( html.join('') );
                }
            }
        });
    };

}( jQuery ));


$j(document).ready(function() {
    
    if($j('.region-header .related-breadcrumb').length > 0) {
        $j('.region-header .related-breadcrumb').droplist({
            overflow: "right", 
            ellipsis: false
        });
    }
    
    if($j('.region-header .breadcrumb').length > 0) {
        $j('.region-header .breadcrumb').droplist({
            overflow: "left", 
            ellipsis: false
        });
    }
    
    
    
    $j(window).on('resize', function() {
         if($j('.region-header .related-breadcrumb').length > 0) {
            $j('.region-header .related-breadcrumb').droplist({
                overflow: "right", 
                ellipsis: false
            });
        }

        if($j('.region-header .breadcrumb').length > 0) {
            $j('.region-header .breadcrumb').droplist({
                overflow: "left", 
                ellipsis: false
            });
        }
        
        $j('.droplist-dropdown').on('click', function(e){
            if ( $j(this).hasClass('open2') ) {
                $j(this).find('.droplist-menu').fadeOut();
                $j(this).removeClass('open2');
            } else {
                e.stopPropagation();
                $j(this).find('.droplist-menu').fadeIn();
                $j(this).addClass('open2');
            }
        });
    })
    
    $j('.droplist-dropdown').on('click', function(e){
        if ( $j(this).hasClass('open2') ) {
            $j(this).find('.droplist-menu').fadeOut();
            $j(this).removeClass('open');
        } else {
            e.stopPropagation();
            $j(this).find('.droplist-menu').fadeIn();
            $j(this).addClass('open2');
        }
    });
})

$j(document).click(function(e){
    $j('.droplist-dropdown').removeClass('open2');
    $j(this).find('.droplist-menu').fadeOut();
});