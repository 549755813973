
var ProductsUsedModeration = function (container, options) {
    
    this.options = options;
    this.container = container;
    this.$container = $j(container);
    this.$rows = $j('.views-row', this.$container);
    
    
    this.activate = function() {
        this.resetState();
        this.bind();
        this.showHiddenElements();
        this.initBrowserBehaviour();
    };

    this.initBrowserBehaviour = function() {
        var self = this;
        $j(window).on('hashchange', function(e,s) {
            if(!window.location.hash.length) {
                self.closeRows();    
            }

        });
    };
    
    this.showHiddenElements = function() {
        this.$container.removeClass('element-invisible');
        $j('.element-invisible', this.$container).each(function() {
            $j(this).removeClass('element-invisible');
        });
    };
    
    this.bind = function() {
        var that = this;
        this.$rows.each(function() {
            that.bindSizeSwitcher(this);
            that.bindActionButtons(this);
            that.bindActionPanel(this);
        });
    };
    
    this.resetState = function() {
        this.closeRows();
        $j('.add_brand').remove();
    };
    
    this.toggleRow = function (row,event) {
        var $row = $j(row);
        
        if ($row.hasClass('closed')) {
            this.openRow($row);
            window.location.hash = 'edit';    
        } else {
            if (event) {
                event.preventDefault();
            }
            window.history.back(1);
            this.closeRow($row);
        }
    };
    
    this.openRow = function(row) {
        var $row = $j(row);
        
        $row.addClass('open');
        $row.removeClass('closed');
        
        this.openSizeSwitchers($row);
        this.closeRowsOtherThan(row);
        this.initializeSlider($row);
    };
    
    this.closeRow = function(row) {
        var $row = $j(row);

        $row.addClass('closed');
        $row.removeClass('open');
        this.closeSizeSwitchers($row);

        this.closeAllAddPopups();
    };
    
    this.closeRows = function() {
        this.closeRowsOtherThan(null);
    };
    
    this.closeRowsOtherThan = function(row) {
        var that = this;
        this.$rows.each(function() {
            if (row 
                && $j(row).length 
                && $j(row)[0] === $j(this)[0]
            ) {
                return;
            }
            
            that.closeRow(this);
        });
    };
    
    this.bindSizeSwitcher = function(row) {
        var that = this;
        var $row = $j(row);
        
        this.$sizeSwitchersOf($row).click(function(event) {
            event.stopPropagation();            
            that.toggleRow($row, event);
        });
    };
    
    this.bindActionPanel = function(row) {
        var that = this;
        var $row = $j(row);
        
        $j('.action-panel, .views-field-title', $row).click(function(event) {
            event.stopPropagation();
            that.toggleRow($row);
        });
    };
    
    this.bindActionButtons = function(row) {
        var that = this;
        
        this.$deleteButtonOf(row).click(function(event) {
            event.stopPropagation();            
            if (!confirm('Czy jesteś pewien, że chcesz usunąć to ogłoszenie?')) {
                return;
            }
            that.performAction("delete/" + that.productIdOf(row));
        });
        
        this.$publishButtonOf(row).click(function(event) {
            event.stopPropagation();            
            if (!confirm('Czy jesteś pewien, że chcesz opublikować to ogłoszenie?')) {
                return;
            }
            that.performAction("publish/" + that.productIdOf(row));
        });
        
        this.$unpublishButtonOf(row).click(function(event) {
            event.stopPropagation();            
            if (!confirm('Czy jesteś pewien, że chcesz cofnąć publikację ogłoszenia?')) {
                return;
            }
            that.performAction("unpublish/" + that.productIdOf(row));
        });
        this.$editButtonOf(row).click(function(event) {
            event.stopPropagation();            
        });

        this.$addBrandButton(row).click(function(event) {
            event.stopPropagation();
            that.closeAllAddPopups();
            var addBrandButton = this;

            $j(addBrandButton).after('<img src="/sites/all/themes/korbanek/images/loading.gif" class="ajax-loading">');

            $j.get('/admin/korbanek/add_brand', {node_id:$j(addBrandButton).closest('.action-panel').attr('data-product-id')}, function(data){
                $j(addBrandButton).after(data);
                $j('.ajax-loading').remove();
                new Awesomplete('.awesomplete');

                that.$addBrandSubmitButton(row).unbind('click').click(function(event) {
                    event.stopPropagation();

                    var brandName = $j.trim($j('input[name="brand_name"]').val());
                    var nodeId = $j.trim($j('input[name="node_id"]').val());

                    if (brandName == "" || nodeId == "") {
                        alert("Wszystkie pola są wymagane.");
                        return false;
                    }

                    $j.ajax({
                        url: $j(this).closest('form').attr('action'),
                        data : {
                            ajax: "1",
                            brand_name: brandName,
                            node_id: nodeId
                        }
                    })
                        .done(function (data) {

                            $j(addBrandButton).closest('.brand')
                                .removeClass('Inna')
                                .removeClass('marka')
                                .html('Marka: <span><b>' + brandName + '</b></span>');

                            that.$addBrandCloseButton(row).click();
                        });

                    return false;
                });

                that.$addBrandCloseButton(row).unbind('click').click(function(event) {
                    event.stopPropagation();
                    $j('.add_brand').remove();
                    return false;
                });

                that.stopPropagationForPopapInputs();

            });
        });

        this.$addCategoryButton(row).click(function(event) {
            event.stopPropagation();
            that.closeAllAddPopups();
            var addCategoryButton = this;

            $j(addCategoryButton).after('<img src="/sites/all/themes/korbanek/images/loading.gif" class="ajax-loading">');

            $j.get('admin/korbanek/add_category', {node_id:$j(addCategoryButton).closest('.action-panel').attr('data-product-id')}, function(data){
                $j(addCategoryButton).after(data);
                $j('.ajax-loading').remove();
                new Awesomplete('.awesomplete');

                that.$addCategorySubmitButton(row).unbind('click').click(function(event) {
                    event.stopPropagation();

                    var categoryName = $j.trim($j('input[name="category_name"]').val());
                    var categoryAssigned = $j.trim($j('select[name="category_assigned"]').val());
                    var nodeId = $j.trim($j('input[name="node_id"]').val());

                    if (categoryName == "" || nodeId == "") {
                        alert("Wszystkie pola są wymagane.");
                        return false;
                    }

                    $j.ajax({
                        url: $j(this).closest('form').attr('action'),
                        data : {
                            ajax: "1",
                            category_name: categoryName,
                            category_assigned: categoryAssigned,
                            node_id: nodeId
                        }
                    })
                        .done(function (data) {

                            $j(addCategoryButton).closest('.category')
                                .removeClass('Inne')
                                .removeClass('maszyny')
                                .html('Kategoria: <span>' + categoryName + '</span>');

                            that.$addCategoryCloseButton(row).click();
                        });

                    return false;
                });

                that.$addCategoryCloseButton(row).unbind('click').click(function(event) {
                    event.stopPropagation();
                    $j('.add_category').remove();
                    return false;
                });

                that.stopPropagationForPopapInputs();
            });
        });


        this.$applyBrandButton(row).click(function(event) {
            event.stopPropagation();
            that.closeAllAddPopups();
            var applyBrandButton = this;

            $j(applyBrandButton).after('<img src="/sites/all/themes/korbanek/images/loading.gif" class="ajax-loading">');

            $j.get('/admin/korbanek/apply_brand', {node_id:$j(applyBrandButton).closest('.action-panel').attr('data-product-id')}, function(data) {
                $j('.ajax-loading').remove();

                if (data == '1') {
                    $j(applyBrandButton).parent().addClass('applied');

                    that.$applyBrandButton(row).remove();
                } else {
                    alert('Error');
                }
            });
        });
        
        this.$applyCategoryButton(row).click(function (event) {
            event.stopPropagation();
            that.closeAllAddPopups();
            var applyCategoryButton = this;

            $j(applyCategoryButton).after('<img src="/sites/all/themes/korbanek/images/loading.gif" class="ajax-loading">');

            $j.get('/admin/korbanek/apply_category', {node_id: $j(applyCategoryButton).closest('.action-panel').attr('data-product-id')}, function (data) {
                $j('.ajax-loading').remove();

                if (data == '1') {
                    $j(applyCategoryButton).parent().addClass('applied');

                    that.$applyCategoryButton(row).remove();
                } else {
                    alert('Error');
                }
            });
        });


    };

    this.stopPropagationForPopapInputs = function() {
        $j('.add_brand input[type="text"], .add_category input[type="text"]').click(function(event) {
            event.stopPropagation();
        });

        $j('.add_brand select, .add_category select').click(function(event) {
            event.stopPropagation();
        });

        $j('.add_brand, .add_category').click(function(event) {
            event.stopPropagation();
        });
    };

    this.closeAllAddPopups = function() {
        $j('.add_brand').remove();
        $j('.add_category').remove();
    };
    
    this.productIdOf = function(row) {
        var $row = $j(row);
        
        var $panel = $j('.action-panel', $row);
        
        if (!$panel.data('product-id')) {
            throw "data-product-id attribute is missing";
        }
        return $panel.data('product-id');
    };
    
    this.$deleteButtonOf = function(row) {
        var $row = $j(row);
        return $j('.action-delete', $row);
    };
    
    this.$publishButtonOf = function(row) {
        var $row = $j(row);
        return $j('.action-publish', $row);
    };
    
    this.$unpublishButtonOf = function(row) {
        var $row = $j(row);
        return $j('.action-unpublish', $row);
    };
    
    this.$editButtonOf = function(row) {
        var $row = $j(row);
        return $j('.action-edit', $row);
    };

    this.$applyBrandButton = function(row) {
        var $row = $j(row);
        return $j('.apply-brand-btn', $row);
    };

    this.$applyCategoryButton = function(row) {
        var $row = $j(row);
        return $j('.apply-category-btn', $row);
    };


    this.$addBrandButton = function(row) {
        var $row = $j(row);
        return $j('.add-brand-btn', $row);
    };

    this.$addBrandCloseButton = function(row) {
        var $row = $j(row);
        return $j('.close_add_brand', $row);
    };

    this.$addBrandSubmitButton = function(row) {
        var $row = $j(row);
        return $j('.form-submit', $row);
    };

    this.$addCategoryButton = function(row) {
        var $row = $j(row);
        return $j('.add-category-btn', $row);
    };

    this.$addCategoryCloseButton = function(row) {
        var $row = $j(row);
        return $j('.close_add_category', $row);
    };

    this.$addCategorySubmitButton = function(row) {
        var $row = $j(row);
        return $j('.form-submit', $row);
    };


    this.$sizeSwitchersOf = function(row) {
        var $row = $j(row);
        return $j('.size-switcher', $row);
    };
    
    this.openSizeSwitchers = function(row) {
        var $row = $j(row);
        this.$sizeSwitchersOf($row).text("Zamknij");
    };
    
    this.closeSizeSwitchers = function(row) {
        var $row = $j(row);
        this.$sizeSwitchersOf($row).text("Otwórz");
    };
    
    this.performAction = function(action) {
        location = 'maszyny-uzywane-moderacja/' + action;
    };
    
    this.initializeSlider = function($row) {
            $row.find('.product-used-gallery .main-image').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                infinite: false,
                adaptiveHeight: false,
                arrows: false,
                asNavFor: '.product-used-gallery .thumbnails ul',
            });

            $row.find('.product-used-gallery .thumbnails ul').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                infinite: false,
                arrows: true,
                focusOnSelect: true,
            asNavFor: '.product-used-gallery .main-image',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            });
    };
    
    this.destroySlider = function($row) {
        if($row.find('.product-used-gallery .main-image').length > 0) {
            $row.find('.product-used-gallery .main-image').slick('destroy');
        }

        if($row.find('.product-used-gallery .thumbnails ul').length > 0) {
            $row.find('.product-used-gallery .thumbnails ul').slick('destroy');
        }

    }
};


ProductsUsedModeration.initialize = function (selector, options) {
    $j(selector).each(function (idx, e) {
        var controller = new ProductsUsedModeration(e, options);
        controller.activate();
    });
};
