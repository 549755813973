

var KorbanekAjax = function() {
    
};

KorbanekAjax.call = function(url, options) {
    
    if (options.onBeforeSending) {
        options.onBeforeSending();
    }
    
    return $j.ajax( url, options)
        .always(function () {
            if (options.onAfterSending) {
                options.onAfterSending();
            }
        })
        .done(function (data) {
            if (typeof(data.status) !== 'undefined') {
                if (options.onDone) {
                    options.onDone(processResponse(data));
                }
            } else {
                if (options.onDone) {
                    options.onDone(processResponse({
                        status : false,
                        errors : [ 'Wystąpił problem z serwerem' ],
                        messages : []
                    }));
                }
            }
        })
        .fail(function (jqXHR, textStatus, errorThrown ) {
            var ct = jqXHR.getResponseHeader("content-type") || "";
            var isJson = ct.indexOf('json') > -1;
            var isValid = false;
            var data = null;
            
            if (isJson) {
                try{
                    data = JSON.parse(jqXHR.responseText);
                    isValid = typeof(data.status) !== 'undefined';
                } catch  (error) {
                    isValid = false;  
                }
            }
            
            if (isJson && isValid) {
                if (options.onDone) {
                    options.onDone(processResponse(data));
                }
            } else {
                if (options.onDone) {                    
                    options.onDone(processResponse({
                        status : false,
                        errors : [ 'Wystąpił problem.' ],
                        messages : []
                    }));
                }
            }
        })
    ;
    
    function processResponse(response) {
        if (!response.error && response.errors) {
            response.error = response.errors.join(";\n")
        }
        return response;
    }
};


$j(document).ready(function() {
    
    $j('.tooltipster').tooltipster({
        theme: 'tooltipster-light',
        maxWidth: 350,
    });
});