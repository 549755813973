
$j(document).on('ready', function() {
    // enable hidden fields validation, for purpose of modal dialogs
    jQuery.fn.validator.Constructor.INPUT_SELECTOR = ':input:not([type="submit"], button):enabled';

    KorbanekContactForm.initialize('.korbanek-contact-form', []);
    KorbanekDropzone.initialize('.korbanek-dropzone', []);
    KorbanekInputFieldsMasking.initialize('[data-mask-pattern]', []);        
    KorbanekImagesCollector.initialize('.korbanek-images-collector', []);
    KorbanekProductUsedForm.initialize('.korbanek-product-used-form', []);    
});


