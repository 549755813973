$j(window).on('load', function() {
    "use strict";
    
    if('#meet-korbanek-team') {

        var tabs = $j('#meet-korbanek-team p[data-toggle-target]');
        var containers = $j('#meet-korbanek-team div[data-toggle-container]');

        $j('.teams-grid.pinterest-like-grid').pinto({
            itemWidth:580,
            autoResize: true,
            resizeDelay: 200,
            gapX:10,
            gapY:10,
        });

        $j('.team-lead-grid.pinterest-like-grid').pinto({
            itemWidth: 410,
            autoResize: true,
            resizeDelay: 200,
            gapX:10,
            gapY:10,
        });        
    }
    if('.dealer-media.row') {

        $j('.dealer-media.row').pinto({
            itemWidth:320,
            autoResize: true,
            resizeDelay: 200,
            gapX:10,
            gapY:30,
        });
    }
    
});
