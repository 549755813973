$j = jQuery.noConflict();

window.videoPlayer = function () {};

window.videoPlayer.prototype = {
    
    videos: [],
    
    
    initialize: function () {
        this.videos = $j('video');
        this.processPosters();
        this.allowPlayPause();
    },
    
    allowPlayPause: function () {
        var player = this;
        this.videos.each(function () {
            var video = $j(this).get(0);

            if (video.paused) {
                player.toggleControls(video);
            }

            $j(this).on('click', function (el) {
                player.playPause(video);
                
            });
        });
    },
    
    playPause: function (video) {
        if (video.paused) {
            
            if (!$j(video).data('been-played')) {
                $j(video).data('been-played', true);
                this.rewindVideoToBeginning(video);
            }
            
            video.play();
            this.toggleControls(video);
        } else {
            video.pause();
            this.toggleControls(video);
        }
    },
    
    toggleControls: function (video) {
        var control = $j(video).get(0).previousElementSibling;
        if ($j(control).is(':visible')) {
            $j(control).hide();
        } else {
            $j(control).show();
        }
    },
    
    processPosters: function () {
        var player = this;
        this.videos.each(function (idx, video) {
            var hasPoster = $j(video).attr('poster') ? true : false;
            if ( !hasPoster ) {
                player.rewindVideoToPosterFrame(video);
            }
        });
    },
    
    rewindVideoToPosterFrame : function(video) {
        var frame = $j(video).data('poster-frame');
        if ( frame > 0 ) {
            video.currentTime = frame;
        }
    },

    rewindVideoToBeginning : function(video) {
        video.currentTime = 0;
    }
};

$j(document).ready(function () {
    if ($j('video').length > 0) {
        var korbanekVideoPlayer = new videoPlayer();
        korbanekVideoPlayer.initialize();
    }
});