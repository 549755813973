
var KorbanekImagesCollector = function(wrapperElement, options) {
    
    this.constructor = function() {
        this.wrapperElement = wrapperElement;
        this.$wrapperElement = $j(wrapperElement);
        this.$wrapperElement.data('korbanek-images-collector', this);
        this.$imageItems = $j('.image-item', this.$wrapperElement);
        this.$addImageButtons = $j('.image-add', this.$wrapperElement);
        this.$imageItemTemplate = this.$imageItems.first().clone();
        this.$imageItemsWrapper = $j('.image-items', this.$wrapperElement);

        this.defaultOptions = {
            defaultImageCtrlOptions : {}
        };

        this.options = $j.extend( this.defaultOptions, options );
    };
    
    this.activate = function() {
        var that = this;
        
        this.$imageItems.each(function(idx, imageElement) {
            that.addNewImage(imageElement);
        });
        
        this.$addImageButtons.on('click', function(){
            var newImageElement = that.$imageItemTemplate.clone();
            newImageElement.appendTo(that.$imageItemsWrapper);
            that.addNewImage(newImageElement);
        });
    };
    
    this.removeImage = function(element) {
        $j(element).data('korbanek-image-collector-ctrl').deactivate();
        $j(element).remove();
    };
    
    this.removeAll = function() {
        var $images = this.currentImageItems();
        $images.each(function(){
            var ctrl = $j(this).data('korbanek-image-collector-ctrl');
            ctrl.deactivate();
        });
        $images.remove();
    };
    
    this.addNewImage = function(element) {
        var that = this;
        
        var ctrlr = new KorbanekImageCollectorCtrl($j(element), 
                            this.options.defaultImageCtrlOptions);
        
        $j(element).on('remove', function(){
            that.removeImage(element);
            that.triggerOnChange();
        });
        
        ctrlr.activate();
    };
    
    this.getAccepptedImages = function() {
        var ret = [];
        this.currentImageItems().each(function(){
            var ctrl = $j(this).data('korbanek-image-collector-ctrl');
            var file = ctrl.getAcceptedFile();
            if (file) {
                ret.push(file);
            }
        });
        return ret;
    };
    
    this.isRequired = function() {
        return !!this.$wrapperElement.data('is-required');
    };
    
    this.isEmpty = function() {
        return !this.atLeastOneIsValid();
    };
    
    this.hasRejectedImages = function() {
        var hasError = false;
        this.currentImageItems().each(function(){
            var ctrl = $j(this).data('korbanek-image-collector-ctrl');
            if (ctrl.isState('error')) {
                hasError = true;
            }
        });
        return hasError;
    };
    
    this.isWorking = function() {
        var atLeastOneIsWorking = false;
        this.currentImageItems().each(function(){
            var ctrlr = $j(this).data('korbanek-image-collector-ctrl');
            if (ctrlr.isState('checking')) {
                atLeastOneIsWorking = true;
            }
        });
        return atLeastOneIsWorking;
    };
    
    this.allImagesAreValid = function() {
        var isOk = true;
        this.currentImageItems().each(function(){
            var ctrl = $j(this).data('korbanek-image-collector-ctrl');
            if (!ctrl.isState('ok')) {
                isOk = false;
            }
        });
        return isOk;
    };
    
    this.atLeastOneIsValid = function() {
        var isOk = false;
        this.currentImageItems().each(function(){
            var ctrl = $j(this).data('korbanek-image-collector-ctrl');
            if (ctrl.isState('ok')) {
                isOk = true;
            }
        });
        return isOk;
    };
    
    this.currentImageItems = function() {
        return $j(this.$imageItems.selector, this.$wrapperElement);
    };
    
    this.triggerOnChange = function() {
        this.$wrapperElement.trigger('changed');
    };
    
    this.constructor();
};

KorbanekImagesCollector.initialize = function(selector, options) {
    $j(selector).each(function(idx, element) {
        var contactForm = new KorbanekImagesCollector(element, options);
        contactForm.activate();
    });
};


var KorbanekImageCollectorCtrl = function(wrapperElement, options) {
    
    this.constructor = function() {
        this.wrapperElement = wrapperElement;
        this.$wrapperElement = $j(wrapperElement);
        this.$wrapperElement.data('korbanek-image-collector-ctrl', this);
        this.$previewImage = $j('img.image-preview', this.$wrapperElement);
        this.$urlInput = $j('input.image-url', this.$wrapperElement);
        this.$messageBox = $j('.image-message', this.$wrapperElement);
        this.$removeButtons = $j('.image-remove', this.$wrapperElement);
        this.states = [ 'idle', 'checking', 'error', 'ok' ];
        this.state = 'idle';
        this.imageValidatorAjaxHandler= null;
        this.deleyedCheckingTimeMs = 700;
        this.deleyedCheckingTimerHandler = null;
        this.acceptedFile = null;

        this.defaultOptions = {
            imageValidatorUrl : Drupal.settings.basePath + "kontakt/image/validator?ajax=1",
            noImageUrl : Drupal.settings.basePath + "sites/default/files/no_image.jpg",
            loadingIndicatorUrl : Drupal.settings.basePath + "sites/default/files/loading_indicator.gif",
            inputScanningIntervalMs : 100,
        };

        this.options = $j.extend( this.defaultOptions, options );
    };
    
    this.activate = function() {
        var that = this;
        
        this.bindInputChange();    
        this.setStateIdle();
        this.$removeButtons.on('click', function(){
            that.$wrapperElement.trigger('remove');
        });
    };
    
    this.deactivate = function() {
        if (this.inputValidatorHandler) {
            window.clearTimeout(this.inputValidatorHandler)
            this.inputValidatorHandler = null;
        }
        if (this.deleyedCheckingTimerHandler) {
            clearTimeout(this.deleyedCheckingTimerHandler);
            this.deleyedCheckingTimerHandler = null;
        }
    };
    
    this.bindInputChange = function() {
        var that = this;
        
        this.$urlInput.data('korbanek-images-collector-last-input-value', this.$urlInput.val());

        this.inputValidatorHandler = window.setInterval(function() {
            if (that.$urlInput.val() !== that.$urlInput.data('korbanek-images-collector-last-input-value')) {
                that.$urlInput.data('korbanek-images-collector-last-input-value', that.$urlInput.val());
                that.onInputChange(that.$urlInput.val());
            }
        }, this.options.inputScanningIntervalMs);        
    };
    
    
    this.onInputChange = function(value) {
        var that = this;
        
        if (this.deleyedCheckingTimerHandler) {
            clearTimeout(this.deleyedCheckingTimerHandler);
            this.deleyedCheckingTimerHandler = null;
        }
        
        if (this.imageValidatorAjaxHandler) {
            this.imageValidatorAjaxHandler.abort();
            this.imageValidatorAjaxHandler = null;
        }
        
        if (!value.trim()) {
            this.setStateIdle();
            return;
        }
        
        if (!this.isValidUrl(value)) {
            this.setStateError("Nieprawidłowy adres url");
            return;
        }
        
        this.deleyedCheckingTimerHandler = setTimeout(function() { 
            that.setStateChecking();
            that.imageValidatorAjaxHandler = KorbanekAjax.call(
                that.options.imageValidatorUrl, 
                {
                    data : {
                        imageUrl : value
                    },
                    method : 'post',
                    onDone : function(response) {
                        that.imageValidatorAjaxHandler = null;
                        that.onResponseReceived(response);
                    }
                }
            );
        }, that.deleyedCheckingTimeMs);
    };
    
    this.onResponseReceived = function(response) {
        if (response.status && response.file) {
            this.acceptedFile = response.file;
            
            this.setStateOk(response.previewUrl);
        } else {
            this.acceptedFile = null;
            
            if (!response.error) {
                this.setStateError('Błąd serwera')
                return;
            }
            
            if (!response.file && !response.error) {
                this.setStateError('Nie udało się zarejestrować pliku na serwerze')
                return;
            }
            
            this.setStateError(response.error);
        }
    };
    
    this.getAcceptedFile = function() {
        return this.acceptedFile;
    };
    
    this.setStateChecking = function() {
        this.setState('checking');
        this.setImage(this.options.loadingIndicatorUrl);
        this.setMessage('Sprawdzam...');
    };
    
    this.setStateError = function(error) {
        this.setState('error');
        this.setImage(null);
        this.setMessage(error);
    };
    
    this.setStateOk = function(imageUrl) {
        this.setState('ok');
        this.setImage(imageUrl);
        this.setMessage(null);
    };
    
    this.setStateIdle = function() {
        this.setState('idle');
        this.setImage(null);
        this.setMessage(null);
    };
    
    this.isState = function(state) {
        return this.state === state;
    };
    
    this.setImage = function(src) {
        if (!src) {
            src = this.options.noImageUrl;
        }
        this.$previewImage.attr('src', src);
    };
    
    this.setState = function(state) {
        if (this.states.indexOf(state) === -1) {
            throw "unknown state " + state; 
        }
        
        this.state = state;
        
        for (var i = 0; i < this.states.length; ++i) {
            var classToRemove = 'state-' + this.states[i];
            this.$wrapperElement.removeClass(classToRemove);
        }
        
        var classToAdd = 'state-' + state;
        this.$wrapperElement.addClass(classToAdd);        
        
        this.triggerOnChange();
    };
    
    this.setMessage = function(msg) {
        if (msg === null || typeof(msg) === typeof undefined) {
            msg = '';
        }
        this.$messageBox.text(msg);
    };

    this.isValidUrl = function(str) {
	var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
	return regexp.test(str);        
    };
    
    this.triggerOnChange = function() {
        this.wrapperElement.trigger('changed');
    };
    
    this.constructor();
};
