$j = jQuery.noConflict();


$j(document).ready(function(){
    var tables = $j('table.ck-style');
    if(tables.length > 0) {
        
        tables.each(function(){
            $j(this).wrap('<div class="linear-table-container"></div>');
        })
    }
})




