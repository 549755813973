String.prototype.repeatify = String.prototype.repeatify || function(times) {
    var str = '';

    for (var i = 0; i < times; i++) {
        str += this;
    }

    return str;
};

window.KorbanekEditor = window.KorbanekEditor || {};

KorbanekEditor.onBeforeInstanceDestroyed = function(editor) {
    KorbanekEditor.Slider.disableSlickSlider(editor);
    KorbanekEditor.threesixty.destroySlider(editor);
};

KorbanekEditor.onInstanceReady = function(editor) {
    KorbanekEditor.Poi.bindEvents(editor);
    KorbanekEditor.Slider.initializeEditorSliders(editor);
    KorbanekEditor.threesixty.initlializeSliders(editor);
    KorbanekEditor.addDefaultSection(editor);
};

KorbanekEditor.addDefaultSection = function(editor) {
    if($j(editor).find('body').html() == '<p><br></p>') {
        $j(editor).find('body').prepend('<section class="ck-style bg-white"></section>');
    };
};

KorbanekEditor.threesixty = {
    sliders: null,
    config: {
        selectors: {
            container: '.threesixty-container',
            slider: '.threesixty',
            navbar: '.nav_bar',
            spinner: '.spinner',
            imagesContainer: '.threesixty_images'
        },

        defaults: {
            imgList: '.threesixty_images', // selector for image list
            progress: '.spinner', // selector to show the loading progress
            filePrefix: '', // file prefix if any
            navigation: true,
            responsive: true,
            drag: false
        }
    },

    initializeSlider: function(sliderContainerId,document) {
        var sliderContainer = document.find('#' + sliderContainerId);
        var config = JSON.parse(sliderContainer.find('.threesixty').attr('data-threesixty-slider'));
        sliderContainer.find('.threesixty').each(function() {
            $j(this).ThreeSixty(config);
        })
    },

    initlializeSliders: function(editor) {
        var self = this;
        $j(editor).find(this.config.selectors.container).each(function() {
            self.initializeSlider($j(this).attr('id'), $j(editor));
        });
    },

    destroySlider: function(editor) {
        var self = this;
        $j(editor).find(this.config.selectors.container).each(function() {
            $j(this).find(self.config.selectors.navbar).remove();
            $j(this).find(self.config.selectors.imagesContainer).empty();
            $j(this).find(self.config.selectors.spinner).removeAttr('style');
            $j(this).find(self.config.selectors.spinner).html('<span>0%</span>');
            $j(this).find(self.config.selectors.slider).removeAttr('style');
        });
    }

}

KorbanekEditor.Poi = {
    bindEvents: function(editor) {
        $j(editor).find('body').on('click', '.cd-single-point a', function() {
            var selectedPoint = $j(this).parent('li');
            if( selectedPoint.hasClass('is-open') ) {
                selectedPoint.removeClass('is-open').addClass('visited');
            } else {
                selectedPoint.addClass('is-open').siblings('.cd-single-point.is-open').removeClass('is-open').addClass('visited');
            }
        })
    }
}

KorbanekEditor.templates = {

    wrapperTemplates:[
        {
            "styleId" : 'slider-wrapper-style1',
            "html" : '<div class="container"> <p class="ck-section-title"> <span class="ck-line-below"><span class="thin">[CHUDY TYTUL]</span> [GRUBY TYTUL]</span> </p> <p>&nbsp;</p> <div class="managable-slider-wrapper slider-wrapper-style1"> <div class="managable-slider-container {{sliderId}}" id="{{sliderId}}" data-slider-template="{{sliderTemplate}}" data-slick=\'{\n                        "slidesToShow" : 3,\n                        "infinite": false,\n                        "responsive" : [\n                        {\n                            "breakpoint": 969,\n                            "settings": {\n                                "slidesToShow": 2\n                             }\n                        },\n                        {\n                            "breakpoint": 560,\n                            "settings": {\n                                "slidesToShow": 1\n                            }\n                        }\n                    ]}\'> {{sliderContent}} </div> </div> </div>'
        },
        {
            "styleId" : 'slider-wrapper-style2',
            "html" : '<div class="container"> <p class="ck-section-title"> <span class="ck-line-below"><span class="thin">[CHUDY TYTUL]</span> [GRUBY TYTUL]</span> </p> <p>&nbsp;</p> <div class="managable-slider-wrapper slider-wrapper-style2"> <div class="slider-content"><div class="slider-content-left"><div class="managable-slider-container {{sliderId}}" id="{{sliderId}}-1" data-slider-template="{{sliderTemplate}}" data-slick=\'{"slidesToShow" : 1,"infinite": true,"arrows": true,"asNavFor": ".{{sliderId}}","rtl": true, "dots": false,"speed": 200}\'>{{sliderContent}}</div></div><div class="slider-content-right"></div></div><div class="slider-navigation"></div></div></div>'
        }
    ],


    templates: [
        {
            "styleId" : 'style1',
            "preview" : 'images/templates/preview-1.png',
            "sliderWrapper": "slider-wrapper-style1",
            "reapitify" : 3,
            "html" : '<div class="slider-style1"><img alt="-" class="image-full-width image-same-height" src="/sites/default/files/wysiwyg/images/maszyny-sadownicze.jpg" /> <p class="rtecenter"><br /> <span style="font-size:24px;"><span class="ck-line-below">Lorem Ipsum</span></span></p> <p>&nbsp;</p> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> <p>&nbsp;</p> <p class="rtecenter"><a class="ck-btn default" href="http://onet.pl">Czytaj więcej &gt;&gt;</a></p> </div>'
        },
        {   "styleId" : 'style2',
            "preview" : 'images/templates/preview-2.png',
            "sliderWrapper": "slider-wrapper-style1",
            "reapitify" : 3,
            "html" : '<div class="slider-style2"> <img alt="-" class="image-full-width image-same-height" src="/sites/default/files/wysiwyg/images/maszyny-sadownicze.jpg" /> <p class="rtecenter"><br /> <span style="font-size:24px;"><span class="ck-line-below">Lorem Ipsum</span></span></p> <p>&nbsp;</p> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> <p>&nbsp;</p> <p class="rtecenter"><a class="ck-btn default" href="http://onet.pl">Czytaj więcej &gt;&gt;</a></p> </div>'
        },
        {   "styleId" : 'style3',
            "preview" : 'images/templates/preview-3.png',
            "sliderWrapper": "slider-wrapper-style2",
            "reapitify" : 3,
            "html" : '<div class="slider-style3">\n<img alt="-" class="image-full-width image-same-height" src="/sites/default/files/wysiwyg/images/maszyny-sadownicze.jpg" /><i class="fa fa-info fa-6">&nbsp;</i><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>\n</div>'
        }

    ],

    getConfigArray: function(pluginPath) {
        var arr = [];
        this.templates.forEach(function(el) {
            var el = [ '<img src="' + pluginPath + el.preview + '"/>', el.styleId];
            arr.push(el);
        });

        return arr;
    },

    getWrapperHtml: function(styleId) {
        var el = this.wrapperTemplates.filter(function(el) {
            return el.styleId == styleId;
        })

        return el[0].html;
    },

    getTemplateElement: function(styleId) {
        return this.templates.filter(function(el) {
            return el.styleId == styleId;
        })
    },

    getTemplateWrapper: function(styleId) {
        var el = this.getTemplateElement(styleId);

        return this.getWrapperHtml(el[0].sliderWrapper);
    },

    getTemplateHtml: function(styleId, repeat) {
        var el = this.getTemplateElement(styleId);

        return el[0].html.repeatify(repeat);

    },

    getRepeatition: function(styleId) {
        var el = this.getTemplateElement(styleId);

        return el[0].reapitify;
    }
};

KorbanekEditor.Slider = {

    config: {
        selectors: {
            slider: '.managable-slider-container',
            sliderItem: '.slick-slide'
        }
    },

    initializeEditorSliders: function(editor) {
        var sliders = $j(editor).find(this.config.selectors.slider);

        sliders.each(function () {
            $j(this).slick();
        });
    },

    disableSlickSlider: function (editor) {
        $j(editor).find(this.config.selectors.slider).each(function() {
            $j(this).slick('unslick');
        })
    },
};

KorbanekEditor.colors = {
    definitions: [
        {
            name: 'Massey Ferguson',
            color: '#bc0a28'
        },
        {
            name: 'Fendt',
            color: '#005d1c'
        },
        {
            name: 'Monosem',
            color: '#007ac3'
        },
        {
            name: 'Agrisem',
            color: '#FFD300'
        },
        {
            name: 'Metaltech',
            color: '#ea0b01'
        },
        {
            name: 'Expom',
            color: '#ff1818'
        },
        {
            name: 'Sulky',
            color: '#008080'
        },
        {
            name: 'Meprozet',
            color: '#014589'
        },
        {
            name: 'Lemken',
            color: '#009edf'
        },
        {
            name: 'Unia Group',
            color: '#8dc63f'
        },
        {
            name: 'Challenger',
            color: '#ffc72c'
        },
        {
            name: 'Berthoud',
            color: '#0043a5'
        },
        {
            name: 'Gregoire Besson',
            color: '#ff0000'
        },
        {
            name: 'Stoll',
            color: '#202123'
        },
        {
            name: 'Fella',
            color: '#C40038'
        },
        {
            name: 'Jeantil',
            color: '#ea001e'
        },
        {
            name: 'MX',
            color: '#58585a'
        },
        {
            name: 'Kramer',
            color: '#148a70'
        },
        {
            name: 'Krukowiak',
            color: '#26bf4d'
        },{
            name: 'Rabe',
            color: '#1d5eb5'
        },
        {
            name: 'Samasz',
            color: '#1f8d2b'
        },
        {
            name: 'Cynkomet',
            color: '#333'
        },
        {
            name: 'Lucas',
            color: '#9E0B0F'
        },
        {
            name: 'Mandam',
            color: '#07D'
        },
        {
            name: 'Kemper',
            color: '#ed1c24'
        },
        {
            name: 'Ls',
            color: '#476ca8'
        },
        {
            name: 'Junkkari',
            color: '#b4d333'
        },
        {
            name: 'Capello',
            color: '#e10512'
        },
        {
            name: 'Sky',
            color: '#C2CF39'
        },
        {
            name: 'Polaris',
            color: '#034b91'
        },
        {
            name: 'Rostselmash',
            color: '#DB0029'
        },
        {
            name: 'Arbos',
            color: '#005f4e'
        },
        {
            name: 'Maschio Gaspardo',
            color: '#e71e26'
        },
        {
            name: 'Goweil',
            color: '#195d96'
        },
        {
            name: 'McHale',
            color: '#008265'
        }
    ],

    getConfigArray: function() {
        var arr = [];
        this.definitions.forEach(function(el) {
            var el = [ el.name, el.color];
            arr.push(el);
        });

        return arr;
    },
}

document.addEventListener('beforeCkeditorDestroyed', function(e){
    var editor = e.detail.editor;
    KorbanekEditor.onBeforeInstanceDestroyed(editor);
})

$j(window).load(function() {

    /*
    *   Override default Drupal.ckeditorToggle method;
    *   TODO : find better way of doing this (without setTimeout function);
    */ 
    setTimeout(function() {
        Drupal.ckeditorToggle = function(textarea_ids, TextTextarea, TextRTE) {
            if (!CKEDITOR.env.isCompatible) {
                return;
            }

            for (i=0; i<textarea_ids.length; i++){
                if (typeof(CKEDITOR.instances) != 'undefined' && typeof(CKEDITOR.instances[textarea_ids[i]]) != 'undefined'){
                    
                    // Modifications start here (dispatch event)
                    var event = new CustomEvent('beforeCkeditorDestroyed', { 'detail': {
                        editor: $j($j(CKEDITOR.instances[textarea_ids[i]]).get(0).document.$)
                    }});
                    document.dispatchEvent(event);
                    // Modifications ends here

                    Drupal.ckeditorOff(textarea_ids[i]);
                    $j('#switch_' + textarea_ids[i]).text(TextRTE);
                }
                else {
                    Drupal.ckeditorOn(textarea_ids[i]);
                    $j('#switch_' + textarea_ids[i]).text(TextTextarea);
                }
            }
        }
   
    },0);

    /*
    *   On editor init function 
    */
    if (typeof CKEDITOR !== 'undefined') {
        CKEDITOR.on("instanceReady", function (event) {
            var editor = event.editor.document.$;    
            if(typeof KorbanekEditor !== 'undefined') {
               KorbanekEditor.onInstanceReady(editor);  
            }
        });
    }

    /*
    *   Disable sliders before save to prevent saving inline styles in slider html
    */ 
    $j('body.not-front #edit-save-edit').click(function(e) {
        if (typeof(CKEDITOR) === 'undefined') {
            return;
        }

        e.preventDefault();
        for(editorInstance in CKEDITOR.instances) {
            KorbanekEditor.Slider.disableSlickSlider(CKEDITOR.instances[editorInstance].document.$);
            KorbanekEditor.threesixty.destroySlider(CKEDITOR.instances[editorInstance].document.$);
        }
        $j(this).unbind('click').click();
    })
});















