$j(document).ready(function(){

    AutoComplete.UrlCache = [];

    AutoComplete({
        Url: "/szukaj_podpowiedz",
        QueryArg : "query",
        EmptyMessage: "Brak podpowiedzi",
        _Select : function(item) {
            // If we have an url for this phrase, go there
            AutoComplete.defaults._Select.bind(this)(item);
            var text = item.textContent;
            if (typeof(AutoComplete.UrlCache[text]) == 'string'
                && AutoComplete.UrlCache[text]
            ) {
                window.location = AutoComplete.UrlCache[text];
            }
        },
        _Post : function(response) {
            // Our response is different: it contains urls
            try {
                var returnResponse = [];
                var json = JSON.parse(response);
                for (var i = 0; i < Object.keys(json).length; i++) {
                    AutoComplete.UrlCache[json[i].phrase] = json[i].url;
                    returnResponse.push({
                        "Value": json[i].phrase,
                        "Label": this._Highlight(json[i].phrase),
                    });
                }
                return returnResponse;
            }
            catch (event) {
                return '';
            }
        },
        KeyboardMappings: {
            Enter : AutoComplete.defaults.KeyboardMappings.Enter,
            KeyUpAndDown_down : AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_down,
            AlphaNum : AutoComplete.defaults.KeyboardMappings.AlphaNum,
            KeyUpAndDown_up : {
                Conditions : AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up.Conditions,
                Operator : AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up.Operator,
                Event : AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up.Event,
                Callback : function(event) {
                    // call original
                    AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up.Callback.bind(this)(event);

                    // Copy phrase to input during pressing up and down arrows
                    active = this.DOMResults.querySelector("li.active");
                    var autocompleteValue = $j(active).data('autocomplete-value');
                    if (autocompleteValue) {
                        $j('#korbanek-top-searcher').val(autocompleteValue);
                    }
                },
            }
        }
    }, "#korbanek-top-searcher");

    $j('.search-view-pager select.pager').attr('name', '');

    $j('.url-auto-switcher').change(function(a) {
        var url = $j(this).find('option:selected').data('url');
        // console.log(url);
        window.location = url;
    });
});
