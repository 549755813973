$j = jQuery.noConflict();

$j(document).ready(function(){
    var $maps = $j('div[data-map-config]');
    
    if (!$maps.length) {
        return;
    }
    
    var apiKey = 'AIzaSyDbPoXTtPBe1VgGD4X0s3W8UxK64pz6HKU';
    $j.getScript('https://maps.googleapis.com/maps/api/js?language=pl&key=' + apiKey, function() {
        $maps.each(function(){            
            $j(this).GoogleMapPlugin();
        });
    });
});     

    
