$j = jQuery.noConflict();

window.korbanekTheme = function(){};

window.korbanekTheme.prototype = {
    
    collapseAtWindowSizeAttr: 'data-collapse-responsive',
    accordionsToAlive: [],
    accordions: [],
    scrollbarWidth: 0,
    defualtCollapseSize: 480,
    windowWidth: 0,
    
    initialize: function() {
        this.accordions = $j('[' + this.collapseAtWindowSizeAttr + ']');
        this.prepareStaticContent();        
        this.windowWidth = $j(window).width();
        this.countScrollbarWidth();
        this.toggleCollapse();
        this.observeWindow();
    },
    
    prepareStaticContent: function() {
        this.accordionsToAlive = $j('.alive-trigger');
        if(this.accordionsToAlive.length > 0) {
            this.accordionsToAlive.each(function() {
                var id = "12345".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
                $j(this).attr('href', '#' + id);
                var headings = $j(this).closest('.panel-heading');
                $j(headings).each(function() {
                    $j(this).siblings('.panel-collapse').attr('id', id);
                });
            })
        }
    },
    
    countScrollbarWidth: function () {
        var scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    },
    
    observeWindow: function(){
      	var that = this;
      	$j(window).on('resize', $j.proxy(this.observeAccordions, this));
    },
    
    observeAccordions: function(){
        if(this.windowWidth != $j(window).width()) {
            this.windowWidth = $j(window).width();
            this.toggleCollapse();
        }
    },
    
    toggleCollapse: function() {
        
        var that = this;
        this.accordions.each(function(){
            var collapseSize = $j(this).attr(that.collapseAtWindowSizeAttr) ? $j(this).attr(that.collapseAtWindowSizeAttr) : that.defualtCollapseSize;
            if(($j(window).width() + that.scrollbarWidth) <= collapseSize) {
                that.enableCollapsing($j(this));
                $j(this).collapse('hide');
                $j(this).addClass('collapse');
            } else {
                that.disableCollapsing($j(this));
                if(!$j(this).hasClass('in')) {
                    $j(this).collapse('show');
                };
            }
        }); 
        
    },
    
    disableCollapsing: function(container) {
        var triggerElement = $j('[href="#' + container.attr('id') + '"]');
        triggerElement.attr('data-toggle','');
        triggerElement.addClass('click-disabled');
        var accordion = this;
        this.setIsActive(triggerElement);
        triggerElement.on('click', function(e){
            e.preventDefault();
            var clb = $j(this).attr('data-accordion-callback');
            if(clb) accordion[clb](this);	
        })
    },
    
    enableCollapsing: function(container) {
        var triggerElement = $j('[href="#' + container.attr('id') + '"]');
        triggerElement.attr('data-toggle','collapse');
        triggerElement.removeClass('click-disabled');
    },

    setIsActive: function(context) {
    	this.setIsNotActive(context);
    	var container = $j($j(context).attr('href'));
    	var containerActivationSize = container.attr('data-collapse-responsive');
    	if($j(window).width() + this.scrollbarWidth > containerActivationSize) {
    		$j(context).addClass('active-accordion-element');
    		container.addClass('active-accordion-element');
    	}
    },

    setIsNotActive: function(context) {
    	var activeElements = $j(context).closest('.panel').find('.active-accordion-element');
    	if(activeElements) {
    		activeElements.each(function() {
    			$j(this).removeClass('active-accordion-element');	
    		})
    	}
    	
    }
}

$j(document).ready(function(){
    var accordion = new korbanekTheme();
    accordion.initialize();
})

$j(document).on('click', '.yamm .dropdown-menu', function(e) {
  e.stopPropagation()
});