$j = jQuery.noConflict();

window.fullHeightImage = function(){};

window.fullHeightImage.prototype = {

	banners: [],

	initialize: function() {
		this.banners = $j('.image-fixed-height');
		this.showBanners();
	},

	showBanners: function() {
		this.banners.each(function() {
			var image = $j(this).find('img');
			var backgroundImage = $j(image).attr('src');
			var banner = $j(image).closest('.image-fixed-height');
			$j(banner).css({
				"background" : "url(" + backgroundImage + ")",
                                "background-size" : 'cover',
				"background-position-x" : image.attr('data-background-width') + "%",
				"background-position-y" : image.attr('data-background-height') + "%",
			})
		})
	}
}

$j(document).ready(function() {
	if($j('.image-fixed-height').length > 0){
		var fhi = new fullHeightImage();	
		fhi.initialize();
	}
})
