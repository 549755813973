
var KorbanekAbstractForm = function(formElement, options) {
    
    this.defaultOptions = {
        $messagesContainer: $j('[data-remodal-id="modal-message"]'),
        $errorsContainer: $j('[data-remodal-id="modal-error"]'),
        actionUrl: $j(formElement).attr('action') + '?ajax=1',
    };

    this.form = formElement;
    this.$form = $j(formElement);
    this.$form.data('korbanek-form', this);
    this.$formIsInProgress = false;
    this.errorMessages = [];
    this.messages = [];
    this.sentProperlyAtLeastOnce = false;
    this.$dropzones = $j('.korbanek-dropzone', this.$form);
    this.$imagesCollectors = $j('.korbanek-images-collector', this.$form);
    this.$submitButtons = $j('button[type="submit"], input[type="submit"]', this.$form);
    this.$submitButtonsTimer = null;
    this.options = $j.extend(this.defaultOptions, options);
    this.initialState = [];

    this.activate = function () {
        var that = this;
        
        // enable validator
        this.$form.validator().on('submit', function (e) {
            if (e.isDefaultPrevented()) {
                return false;
            }
            e.preventDefault();

            that.clearFormState();

            if (that.validate()) {
                that.doSubmitForm();
            }
            return false;
        });
        
        // validate dropzones after they change
        this.$dropzones.on('changed', function () {
            setTimeout(function () {
                that.clearDropzoneState();
                that.validateRejectedDropzones();
                if (that.isImageUploaderMode()) {
                    that.clearImagesCollectorState();
                    that.validateRejectedImagesCollectors();
                }
            }, 300);
        });

        // validate images collectors after they change
        this.$imagesCollectors.on('changed', function () {
            setTimeout(function () {
                that.clearImagesCollectorState();
                that.validateRejectedImagesCollectors();
                if (that.isImageUploaderMode()) {
                    that.clearDropzoneState();
                    that.validateRejectedDropzones();
                }
            }, 300);
        });

        this.$submitButtonsTimer = setInterval(function(){
            if (that.isWorking()) {
                that.disableSubmitButtons();
            } else {
                that.enableSubmitButtons();
//                that.validateForm();
            }
        }, 200);
        
        this.saveInitialState();
    };
    
    this.deactivate = function () {
        if (this.$submitButtonsTimer) {
            clearTimeout(this.$submitButtonsTimer);
            this.$submitButtonsTimer = null;
        }
        
        // todo:
        // this.$dropzones.deactivate();
        // this.$imagesCollectors.deactivate();
    };

    this.hideParentDialog = function () {
        this.$form.closest('.modal').modal('hide');
    };

    this.isWorking = function () {
        return this.$formIsInProgress
            || !this.validateWorkingImagesCollectors()
            || !this.validateWorkingDropzone();
    };
    
    this.validateForm = function() {
        this.$form.validator('validate');
    };
    
    this.disableSubmitButtons = function() {
        this.$submitButtons.attr('disabled', 'disabled');
    };
    
    this.enableSubmitButtons = function() {
        this.$submitButtons.removeAttr('disabled');
    };

    this.validate = function () {
        
        if (this.isWorking()) {
            return false;
        }

        if (!this.validateRejectedDropzones()) {
            this.addErrorMessage('Proszę usunąć nieprawidłowe pliki z obszaru');
            return false;
        }

        if (!this.validateRejectedImagesCollectors()) {
            this.addErrorMessage('Proszę usunąć nieprawidłowe adresy url');
            return false;
        }

        if (this.isImageUploaderMode()) {

            var isAtLeastOneSourceOk = this.validateRequiredDropzones(false)
                    || this.validateRequiredImagesCollectors(false);

            if (!isAtLeastOneSourceOk) {
                this.validateRequiredDropzones(true);
                this.validateRequiredImagesCollectors(true);
                this.addErrorMessage('Proszę załączyć obrazki.');
                return false;
            }
        } else {
            if (!this.validateRequiredDropzones()) {
                this.addErrorMessage('Proszę umieścić pliki w obszarze.');
                return false;
            }

            if (!this.validateRequiredImagesCollectors()) {
                this.addErrorMessage('Proszę podać adres url.');
                return false;
            }
        }

        if (!this.validateCaptcha()) {
            this.addErrorMessage('Potwierdź proszę, że nie jesteś robotem');
            return false;
        }

        return true;
    };

    this.isImageUploaderMode = function () {
        return this.$form.data('form-type') === 'images-uploader';
    };

    this.validateRequiredDropzones = function (mark) {
        var that = this;

        var isOk = true;
        this.$dropzones.each(function () {
            var dropzone = $j(this).data('korbanek-dropzone');

            if (dropzone.isRequired() && dropzone.isEmpty()) {
                isOk = false;
                if (mark) {
                    that.markDropzone(this);
                }
                return;
            }
        });
        return isOk;
    };

    this.validateRejectedDropzones = function () {
        var that = this;

        var isOk = true;
        this.$dropzones.each(function () {
            var dropzone = $j(this).data('korbanek-dropzone');

            if (dropzone.hasRejectedFiles()) {
                isOk = false;
                that.markDropzone(this);
                return;
            }
        });
        return isOk;
    };

    this.validateWorkingDropzone = function () {
        var isOk = true;
        this.$dropzones.each(function () {
            var imagesCollector = $j(this).data('korbanek-dropzone');
            if (imagesCollector.isWorking()) {
                isOk = false;
            }
        });
        return isOk;
    };

    this.validateWorkingImagesCollectors = function () {
        var isOk = true;
        this.$imagesCollectors.each(function () {
            var imagesCollector = $j(this).data('korbanek-images-collector');
            if (imagesCollector.isWorking()) {
                isOk = false;
            }
        });
        return isOk;
    };

    this.validateRejectedImagesCollectors = function () {
        var that = this;

        var isOk = true;
        this.$imagesCollectors.each(function () {
            var imagesCollector = $j(this).data('korbanek-images-collector');
            if (imagesCollector.hasRejectedImages()) {
                isOk = false;
                that.markImagesCollector(this);
                return;
            }
        });
        return isOk;
    };

    this.validateRequiredImagesCollectors = function () {
        var that = this;
        
        var isOk = true;
        this.$imagesCollectors.each(function () {
            var imagesCollector = $j(this).data('korbanek-images-collector');

            if (imagesCollector.isRequired() && imagesCollector.isEmpty()) {
                isOk = false;
                that.markImagesCollector(this);
                return;
            }
        });
        return isOk;
    };

    this.validateCaptcha = function () {
        var isDevMode = typeof (KorbanekCaptchaDisabledForDev) !== 'undefined';
        if (!this.hasFilledCaptcha() && !isDevMode) {
            this.markCaptcha();
            return false;
        }
        return true;
    };

    this.validatePhoneNumber = function(number) {
        number = number.replace("(", "");
        number = number.replace(")", "");

        var regex = /((([^\d+]|^){1300})|.*[a-zA-Z]+\s*)(([^\d+]|[\s]|^)|(\+|00)?48|48\s|(((\+|00)\s?)(\d{1,3})\s)|(((\+|00)\s?)(\d{1,3})\s{1,4})|(\(((\+|00)\s?)(\d{1,3})\))|(\(((\+|00)\s?)(\d{1,3})\)\s{1,4})\s+|(\(\s{0,4}((\+|00)\s?)(\d{1,3})\s{0,4}\)\s{1,4}))((\d[ -]?){9})((([^\d]|$){1300})|\s*[a-zA-Z]+.*)/

        return regex.test(number);
    }

    this.markCaptcha = function () {
        $j('.g-recaptcha', this.$form).addClass('is-required');
    };

    this.markDropzone = function (dropzone) {
        $j(dropzone).addClass('is-required');
    };

    this.markImagesCollector = function (imageCollector) {
        $j(imageCollector).addClass('is-required');
    };

    this.hasFilledCaptcha = function () {
        return $j("[name='g-recaptcha-response']", this.$form).val();
    };

    this.doSubmitForm = function () {
        if (this.sentProperlyAtLeastOnce
                && !confirm("Formularz już raz został poprawnie wysłany. \n"
                        + "Jesteś pewien, że chcesz wysłać formularz drugi raz?")
                ) {
            return;
        }

        KorbanekAjax.call(this.options.actionUrl, {
            method: 'post',
            data: this.prepareRequestData(),
            onBeforeSending: $j.proxy(this, 'onBeforeSending'),
            onAfterSending: $j.proxy(this, 'onAfterSending'),
            onDone: $j.proxy(this, 'onDone')
        });
    };

    this.prepareRequestData = function () {
        // remove all dynamic hidden inputs
        $j('.custom-hidden-input', this.$form).remove();

        this.prepareFormData();
        this.prepareDropzoneData();
        this.prepareImagesCollectorData();
        return this.$form.serialize();
    };

    this.prepareFormData = function () {
        var that = this;
        $j('input, textarea, button, select', this.$form).each(function (idx, e) {
            var fieldName = $j(e).attr('name');

            if (!fieldName
                    || fieldName.indexOf('fields') !== 0) {
                return;
            }

            var $label = $j("label[for='" + fieldName + "']", that.$form);
            if ($label.length <= 0) {
                return;
            }
            
            var inputName = fieldName.replace('fields', 'fields_meta') + '[name]';
            var $metaField = $j('[name="' + inputName + '"]');
            if ($metaField.length > 0) {
                return;
            }
            
            var inputValue = $label.text();
            that.addHiddenInput(inputName, inputValue);
        });
    };

    this.prepareDropzoneData = function () {
        var that = this;

        // plug-in dropzones
        this.$dropzones.each(function (idx, element) {
            var dropzone = $j(element).data('korbanek-dropzone');
            if (!dropzone) {
                return;
            }

            var files = dropzone.getAccepptedBackendFiles();
            for (i = 0; i < files.length; ++i) {
                var file = files[i];

                that.addHiddenInput('dropzone[' + file.id + ']', file.id);
            }
        });

    };

    this.prepareImagesCollectorData = function () {
        var that = this;

        // plug-in dropzones
        this.$imagesCollectors.each(function (idx, element) {
            var imagesCollector = $j(element).data('korbanek-images-collector');
            if (!imagesCollector) {
                return;
            }

            var files = imagesCollector.getAccepptedImages();
            for (i = 0; i < files.length; ++i) {
                var file = files[i];

                that.addHiddenInput('images_collector[' + file.id + ']', file.id);

                that.addHiddenInput('fields[url_' + i + ']', file.url);
                that.addHiddenInput('fields_meta[url_' + i + '][name]', 'Url użytkownika ' + (i + 1));
            }
        });
    };

    this.onBeforeSending = function () {
        this.clearFormState();

        $j('input, textarea, button, select', this.$form).attr('disabled', 'disabled');
        this.$form.addClass("loading-indicator");
        this.$formIsInProgress = true;

        this.addMessage("Wysyłam...");
    };

    this.onAfterSending = function () {
        $j('input, textarea, button, select', this.$form).removeAttr('disabled');
        this.$form.removeClass("loading-indicator");
        this.$formIsInProgress = false;

        grecaptcha.reset(
                $j('.g-recaptcha', this.$form).attr('data-captcha-id')
                );
    };

    this.onDone = function (response) {
        if (response.status) {
            this.sentProperlyAtLeastOnce = true;

            this.clearForm();
            this.hideParentDialog();

            this.$form.addClass("status-ok");
            this.$form.removeClass("status-error");
        } else {
            this.clearFormState();

            this.$form.addClass("status-error");
            this.$form.removeClass("status-ok");
        }

        for (i = 0; i < response.messages.length; ++i) {
            this.addMessage(response.messages[i]);
        }
        for (i = 0; i < response.errors.length; ++i) {
            this.addErrorMessage(response.errors[i]);
        }
    };

    this.clearForm = function () {
        this.clearDropzone();
        this.clearImagesCollector();

        this.clearFormState();
        this.revertToInitialState();
    };

    this.clearFormState = function () {
        this.$form.removeClass("status-ok");
        this.$form.removeClass("status-error");

        this.clearCaptchaState();
        this.clearDropzoneState();
        this.clearImagesCollectorState();

        this.options.$errorsContainer.empty();
        this.options.$messagesContainer.empty();
        this.errorMessages = [];
        this.messages = [];
    };
    
    this.saveInitialState = function() {
        this.initialState = this.$form.values();
    }

    this.revertToInitialState = function() {
        this.$form.values(this.initialState);
    };
    
    this.clearCaptchaState = function () {
        $j('.g-recaptcha', this.$form).removeClass('is-required');
    };

    this.clearDropzone = function () {
        this.$dropzones.each(function (idx, element) {
            var dropzone = $j(element).data('korbanek-dropzone');
            if (!dropzone) {
                return;
            }
            dropzone.removeAllFiles(true);
        });
    };

    this.clearDropzoneState = function () {
        this.$dropzones.removeClass('is-required');
    };

    this.clearImagesCollector = function () {
        this.$imagesCollectors.each(function (idx, element) {
            var imagesCollector = $j(element).data('korbanek-images-collector');
            if (!imagesCollector) {
                return;
            }
            imagesCollector.removeAll();
        });
    };

    this.clearImagesCollectorState = function () {
        this.$imagesCollectors.removeClass('is-required');
    };

    this.addErrorMessage = function (error) {
        if (typeof (this.errorMessages[error]) === 'undefined') {
            this.errorMessages[error] = true;

            this.options.$errorsContainer.append('<p><i class="fa fa-exclamation-triangle"></i> ' + error + '</p>');
            this.options.$errorsContainer.remodal().open();
        }
    };

    this.addMessage = function (message) {
        if (typeof (this.messages[message]) === 'undefined') {
            this.messages[message] = true;

            this.options.$messagesContainer.append('<p>' + message + '</p>');
            this.options.$messagesContainer.remodal().open();
        }
    };

    this.addHiddenInput = function (name, value) {
        $j('<input>').attr({
            type: 'hidden',
            name: name,
            value: value,
            class: 'custom-hidden-input',
        }).appendTo(this.$form);
    };
    
};
