$j.fn.values = function(data) {
    var els = $j(this).find(':input').get();

    if(typeof data != 'object') {
        // return all data
        data = {};

        $j.each(els, function() {
            if (this.name && !this.disabled && (this.checked
                    || /select|textarea/i.test(this.nodeName)
                    || /text|hidden|password|color|date|datetime|datetime-local|email|month|number|range|search|tel|time|url/i.test(this.type))) {
                data[this.name] = $j(this).val();
            }
        });
        return data;
    } else {
        $j.each(els, function() {
            if (this.name && typeof data[this.name] != 'undefined') {
                if(this.type == 'checkbox' || this.type == 'radio') {
                    $j(this).attr("checked", (data[this.name] == $j(this).val()));
                } else {
                    $j(this).val(data[this.name]);
                }
            }
        });
        return $j(this);
    }
};