var KorbanekGeocoder = function() {
    
    this.findLongLat = function(address, onSuccess, onFailure) {
        this.executeGoogleMaps(address, function(data) {
            if (typeof (data[0].geometry.location) == 'undefined') {
                onFailure("Błąd google maps. Nie odnaleziono lokalizacji")
                return;
            }
            var latLng = data[0].geometry.location;
            onSuccess(latLng);
        }, onFailure);
    };
    
    this.findProvince = function(address, onSuccess, onFailure) {
        var that = this;
        this.executeProvinceFinder(address, function(provinceWithState) {
            onSuccess(provinceWithState);
        });
    };

    this.executeGoogleMaps = function(address, onSuccess, onFailure) {
        $j.getJSON('/map/geocode', {
            address: address
        })
        .done(function(result){
            if (typeof(result['results']) == 'undefined' ) {
                onFailure("Błąd serwera");
                return;
            }
            onSuccess(result['results']);
        })
        .fail(function(e) {
            onFailure("Błąd serwera");
        });
    };

    this.executeProvinceFinder = function(address, onSuccess, onFailure) {
        $j.getJSON('/map/province_finder', {
            address: address
        })
            .done(function(result){
                if (typeof(result['province-state-code']) == 'undefined' ) {
                    onFailure("Błąd serwera");
                    return;
                }
                onSuccess(result['province-state-code']);
            })
            .fail(function(e) {
                onFailure("Błąd serwera");
            });
    };

}

