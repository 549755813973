$j = jQuery.noConflict();

window.sameHeightImage = function(){};

window.sameHeightImage.prototype = {

	images: [],
        imageHeight: null,
        initialized: false,
        config: [],
        
	initialize: function(imageSelector, withinConteiner) {
            if(!this.initialized) {
                this.observeWindowWidth();
            }
            this.config = [imageSelector, withinConteiner]
            this.collectImages(imageSelector, withinConteiner);
            this.adjustImages();
	},
        
        collectImages: function(imageSelector, withinConteiner) {
            var _that = this;
            var _images = $j(withinConteiner).find(imageSelector);
            _that.imageHeight = null;
            _images.each(function(){
                _that.images.push($j(this));
                var _imageHeight = $j(this).height();
                if(_that.imageHeight == null || _that.imageHeight < _imageHeight) {
                    _that.imageHeight = _imageHeight;
                }
            })
        },
        
	adjustImages: function() {
            console.log(this.imageHeight);
            for(var i = 0; i < this.images.length; i++) {
                if(this.images[i].height() < this.imageHeight) {
                    this.images[i].css('margin-top', this.imageHeight - this.images[i].height());
                }
            }
	},
        
        observeWindowWidth: function() {
            var _that = this;
            $j(window).on('resize', function() {
                _that.collectImages(_that.config[0], _that.config[1])
                _that.adjustImages();
            });
            
            this.initialized = true;
        }
}

$j(window).load(function() {
//    if($j('.image-same-height').length > 0){
//        var shi = new sameHeightImage();	
//        shi.initialize('.image-same-height', 'section.ck-style');
//    }
})
