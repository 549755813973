$j(document).ready(function(){
 //    if($j('.page-maszyny-uzywane-moderacja').length > 0) {
	// 	var images = document.querySelectorAll('.product-used-gallery .main-image img, .product-used-gallery .thumbnails img');
	//     if(images.length > 0) {
	//         for(var i = 0; i < images.length; i++) {
	//             var imageSource = images[i].getAttribute("src");
	//             images[i].setAttribute('data-lazy', imageSource);
	//             images[i].setAttribute('src', '');
	//         }
	//     }    
	// }

    $j('select.korbanek-products-used-pager-size-switcher').each(function() {
        $j(this).on('change', function(){
            var goto = $j(':selected', this).data('goto');
            window.location = goto;
        });
    });
    
    
    ProductsUsedModeration.initialize('.view-admin-products-used-moderation', {});
    

});