
$j(document).ready(function(){

    function attachRotator($items, imgSelector, inputsProvider) {
        $items.each(function() {
            var $container = $j(this);
            var $img = $container.find(imgSelector);
            var $wrapped = $img.wrap("<span class=\"rotator\"></span>").parent();
            $wrapped.append("<span class='ccw' title=\"Obróć obrazek w lewo o 90 st\"></span>");
            $wrapped.append("<span class='cw' title=\"Obróć obrazek w prawo o 90 st\"></span>");

            var $ccw = $wrapped.find('.ccw');
            var $cw = $wrapped.find('.cw');

            $ccw.click(function() {
                onClick($container, $img, inputsProvider, 'ccw');
            });

            $cw.click(function() {
                onClick($container, $img, inputsProvider, 'cw');
            });
        });
    }

    function onClick($container, $img, inputsProvider, type) {
        var src = $img.attr('src');
        if (!src) {
            return;
        }

        $j.ajax( Drupal.settings.basePath + "admin_rotate_image/" + type + "?" + src)
            .done(function(result) {
                refreshImage($img, result);
                swapImageWidthHeight($img);
                swapHiddenInputsWidthHeight(inputsProvider, $container);
            })
            .fail(function(result) {
                alert("Wystąpił błąd podczas obracania orazka")
                console.log(result);
            });
    }

    function swapHiddenInputsWidthHeight(inputsProvider, $container) {
        var $widthInput = inputsProvider($container, 'width');
        var $heightInput = inputsProvider($container, 'height');

        var tmp = $widthInput.val();
        $widthInput.val($heightInput.val());
        $heightInput.val(tmp);
    }

    function refreshImage($img, newUrl) {
        $img.attr('src', newUrl);
    }

    function swapImageWidthHeight($img) {
        var width = $img.attr('width');
        var height = $img.attr('height');
        if (width && height) {
            $img.attr('width', height);
            $img.attr('height', width);
        }
    }

    function defaultInputsProvider($container, item) {
        var found = false;

        $container.find('input[type=hidden]').each(function() {
            var $input = $j(this);
            var name = $input.attr('name');
            if ( name.indexOf("[" + item + "]") !== -1 ){
                found = $input;
                return false;
            }
        });

        return found;
    }


    attachRotator($j('#edit-field-prodused-photo-und-table .image-widget'), '.image-preview img', defaultInputsProvider);

});
