
var KorbanekProductUsedForm = function (formElement, options) {
    
    var parent = new KorbanekAbstractForm(formElement, options);
    
    KorbanekAbstractForm.call(this, formElement, $j.extend({
        actionUrl: Drupal.settings.basePath + "dodaj-maszyne-uzywana?ajax=1",
        correlationsUrl: Drupal.settings.basePath + "maszyny-uzywane/korelacje",
        provinceFinderUrl: Drupal.settings.basePath + "map/province_finder",
        useRestService : false,
        highlightedSelector : 'option.inne-maszyny, option.inna-marka',
    }, options));
    
    this.$categoriesInitialState = null;
    this.$brandsInitialState = null;
    this.isProcessingCorrelatingDropdowns = false;
    
    
    this.activate = function() {
        var that = this;
        
        parent.activate.call(this);

        this.activateAdministrationDivision();

        this.bindPriceToBeAgreed();
        
        this.deliverCorrelations(function(json) {
            that.activateCorrelations(json.correlations);
        });

        this.bindFormEvents();
    };

    this.activateAdministrationDivision = function() {
        var that = this;

        this.$addrState().on('change', function() {
            var state = $j(this).val();
            that.showListOfProvinces(state);
        });

        this.$addrZip().on('change', function() {
            that.onAddressChanged();
        });
        this.$addrCity().on('change', function() {
            that.onAddressChanged();
        });
    };

    this.bindFormEvents = function() {
        this.bindPhoneSugestions();
    };

    this.bindPhoneSugestions = function() {
        this.$form.find('[data-phone-number-suggestion]').on('blur', function() {
            if($j(this).val().trim() == '') {
                return;
            }

            if(!parent.validatePhoneNumber($j(this).val())) {
                $j('#' + $j(this).attr('data-phone-number-suggestion')).css('display', 'block');
            } else {
                $j('#' + $j(this).attr('data-phone-number-suggestion')).css('display', 'none');
            }            
        });
    };

    this.onAddressChanged = function() {
        var that = this;
        var zip = this.$addrZip().val();
        var city = this.$addrCity().val();

        if (!zip && !city) {
            return;
        }
        var address = zip + ", " + city;
        this.executeProvinceFinder(address, function(province, state) {
            if (province && state) {
                that.showListOfProvinces(state);
                that.$addrState().val(state);
                that.$addrProvince().val(province);
            }
        }, function(error) {
            console.log(error)
        });
    }

    this.executeProvinceFinder = function(address, onSuccess, onFailure) {
        $j.getJSON(this.options.provinceFinderUrl, {
            address: address
        })
            .done(function(result){
                if (typeof (result) == "undefined" ) {
                    onSuccess(null, null);
                }

                if (typeof(result['province-state-code']) == 'undefined' ) {
                    onFailure("Błąd serwera");
                    return;
                }
                onSuccess(result['province'], result['state']);
            })
            .fail(function(e) {
                onFailure("Błąd serwera");
            });
    };

    this.showListOfProvinces = function (state) {
        var that = this;
        var states = this.$addrState().data('administrative-division');

        for (var i = 0; i < states.length; ++i) {
            if (states[i].name !== state) {
                continue;
            }

            var provinces = states[i].provinces;

            that.$addrProvince().closest('.addr-input-province-wrapper').show();
            that.$addrProvince().empty();
            that.$addrProvince().append($j('<option value="" disabled="disabled" selected="selected">-- Wybierz powiat --</option>'));
            for (var j = 0; j < provinces.length; ++j) {
                that.$addrProvince().append($j('<option>', {
                    value: provinces[j],
                    text: provinces[j]
                }));
            }
        }
    }

    this.bindPriceToBeAgreed = function() {
        var that = this;
        this.$priceToBeAgreedCheckbox().change(function() {
            var validator = that.$form.data('bs.validator');
            if ($j(this).is(':checked')) {
                that.$priceInputAmount().prop('required', false);
                that.$priceInputDetails().prop('required', false);

                setTimeout(function() {
                    that.$priceInputAmount().data('bs.validator.previous', 'dummy');
                    that.$priceInputDetails().data('bs.validator.previous', 'dummy');
                    validator.validateInput(that.$priceInputAmount(), false);
                    validator.validateInput(that.$priceInputDetails(), false);

                    that.$priceInputAmount().prop('disabled', true);
                    that.$priceInputDetails().prop('disabled', true);
                }, 200);

            } else {
                that.$priceInputAmount().prop('required', true);
                that.$priceInputDetails().prop('required', true);

                that.$priceInputAmount().prop('disabled', false);
                that.$priceInputDetails().prop('disabled', false);

                setTimeout(function() {
                    that.$priceInputAmount().data('bs.validator.previous', 'dummy2');
                    that.$priceInputDetails().data('bs.validator.previous', 'dummy2');
                    validator.validateInput(that.$priceInputAmount(), false);
                    validator.validateInput(that.$priceInputDetails(), false);
                }, 200);

            }
        });
    };
    
    this.deliverCorrelations = function (onDone) {
        if (this.options.useRestService) {
            $j.get(this.options.correlationsUrl, function(json) {
                if (typeof(json.correlations) === 'object') {
                    onDone(json.correlations); 
                }
            });
        } else {
            try{
                var json = this.$form.data('correlations-json');
                if (typeof(json) === 'object') {
                    onDone(json);
                } else if (typeof(json) === 'string') {
                    onDone($j.parseJSON(json));
                }
            } catch (e) {
                console.log(e.stack || e);
            }
        }
    };
    
    this.activateCorrelations = function(correlations) {
        var that = this;
        
        this.correlations = correlations;
        
        this.$brands().on('change', function() {
            that.adjustCorrelatedDropdownsToCurrentState();
        }); 
        
        this.$categories().on('change', function() {
            that.adjustCorrelatedDropdownsToCurrentState();
        }); 
        
        this.$brandsInitialState = this.$brands().clone(true);
        this.$categoriesInitialState = this.$categories().clone(true); 
    };
    
    this.adjustCorrelatedDropdownsToCurrentState = function() {
        var that = this;
        
        if (this.isProcessingCorrelatingDropdowns) {
            return;
        }

        this.isProcessingCorrelatingDropdowns = true;

        this.restoreCorrelatedDropdowns();

        this.organizeCorrelatedDropdown(this.$brands, this.$categories, this.$categoriesInitialState, function(brandId, categoryId) {
            return that.isCategoryCorrelatedWithBrand(categoryId, brandId);
        });

        this.organizeCorrelatedDropdown(this.$categories, this.$brands, this.$brandsInitialState, function(categoryId, brandId) {
            return that.isCategoryCorrelatedWithBrand(categoryId, brandId);
        });

        this.isProcessingCorrelatingDropdowns = false;
    };

    this.organizeCorrelatedDropdown = function($sourceDropdown, $targetDropdown, $targetDropdownInitialState,  splitingCallback) {
        var that = this;
        
        var $sourceSelectedOption = $sourceDropdown.call(this).find('option:selected');
        
        var $sourceHighlightedOptions = $sourceDropdown.call(this).find(this.options.highlightedSelector);
        var $targetHighlightedOptions = $targetDropdown.call(this).find(this.options.highlightedSelector);
        
        // split elements into 'correlated' and 'others' groups
        var correlatedOptions = [];
        var otherOptions = [];
        
        $targetDropdownInitialState.find('option').each(function(){
            var $option = $j(this);
            var sourceOptionValue = $sourceSelectedOption.attr('value');
            var targetOptionValue = $option.attr('value');
            
            if (splitingCallback(sourceOptionValue, targetOptionValue)) {
                correlatedOptions.push($option);
            } else {
                otherOptions.push($option);
            }
        });
        
        var hasOrdinaryCorrelated = false;
        
        for (i in correlatedOptions) {
            var $option = correlatedOptions[i];
            
            var isHighlightedOption = false;
            $targetHighlightedOptions.each(function() {
                if ($j(this).attr('class') === $option.attr('class')) {
                    isHighlightedOption = true;
                }
            });

            if (!isHighlightedOption) {
                hasOrdinaryCorrelated = true; 
            }
        }
        
        
        var isHighlightedSelected = this.areEqual($sourceHighlightedOptions, $sourceSelectedOption);
        
        if (!correlatedOptions.length || isHighlightedSelected || !hasOrdinaryCorrelated) {
            return;
        }
        
        var value = $targetDropdown.call(this).val();
        
        // Remove all options
        $targetDropdown.call(this).find('option').remove();
        
        // Add correlated tab element
        var sourceName = $sourceSelectedOption.text();
        $targetDropdown.call(this).append('<option disabled value="" class="correlated-marker-item" >-- Pasujące do "' + sourceName + '" --</option>');
        
        // Add correlated items
        for (i in correlatedOptions) {
            $targetDropdown.call(this).append(correlatedOptions[i].clone());
        }
        
        // Add other items
        for (i in otherOptions) {
            $targetDropdown.call(this).append(otherOptions[i].clone());
        }
        
        // Name other categories tab element
        $targetDropdown.call(this).find('.marker-item').text('-- Pozostałe --');
        $targetDropdown.call(this).val(value);
        
        // Move highligted options to the end
        this.$highlightedOptionsOf(this.$brands()).appendTo(this.$brands());
        this.$highlightedOptionsOf(this.$categories()).appendTo(this.$categories());
    };
    
    this.$highlightedOptionsOf = function($dropdown) {
        return $dropdown.find(this.options.highlightedSelector);
    };
    
    this.revertToInitialState = function() {
        parent.revertToInitialState.call(this);
        
        this.restoreCorrelatedDropdowns();
    };
    
    this.restoreCorrelatedDropdowns = function() {
        var categoryId = this.$categories().val();
        var brandId = this.$brands().val();
        
        this.restoreDropdown(this.$categories(), this.$categoriesInitialState);
        this.restoreDropdown(this.$brands(), this.$brandsInitialState);
        
        this.$categories().val(categoryId);
        this.$brands().val(brandId);
        
    };
    
    this.restoreDropdown = function($dropdown, $initialState) {
        $dropdown.find('option').remove();
        $dropdown.append($initialState.find('option').clone());
    };
    
    this.isCategoryCorrelatedWithBrand = function(categoryId, brandId) {
        var key = categoryId + "x" + brandId;
        return !!this.correlations[key];
    };
    
    this.$categories = function() {
        return this.$form.find('[name="data[category]"]');
    };
    
    this.$brands = function() {
        return this.$form.find('[name="data[brand]"]');
    };

    this.$priceInputDetails = function() {
        return this.$form.find('.price-input-details');
    };

    this.$priceInputAmount = function() {
        return this.$form.find('.price-input-amount');
    };

    this.$addrCity = function() {
        return this.$form.find('.addr-input-city');
    };

    this.$addrZip = function() {
        return this.$form.find('.addr-input-zip');
    };

    this.$addrProvince = function() {
        return this.$form.find('.addr-input-province');
    };

    this.$addrState = function() {
        return this.$form.find('.addr-input-state');
    };

    this.$priceToBeAgreedCheckbox = function() {
        return this.$form.find('#price_to_be_agreed_checkbox');
    };
    
    this.areEqual = function($selector1, $selector2) {
        if ($selector1.length !== $selector2.length) {
            return false;
        }
        
        var len = $selector1.length;
        for (var i = 0; i < len; ++i) {
            if ($selector1.get(i) !== $selector2.get(i)) {
                return false;
            }
        }
        
        return true;
    };
    
};

KorbanekProductUsedForm.prototype = new KorbanekAbstractForm();


KorbanekProductUsedForm.initialize = function (formSelector, options) {
    $j(formSelector).each(function (idx, e) {
        var contactForm = new KorbanekProductUsedForm(e, options);
        contactForm.activate();
    });
};


