$j(document).ready(function() {
    designSize = 1920;

    // if($j(window).width() > designSize) {
    //     var zoom = ($j(window).width() / designSize) * 100;
    //     $j('body').css('zoom', zoom + '%');
    // } else {
    //     $j('body').css('zoom',0);
    // }

    // Threesixty slider
    if($j('.threesixty').length > 0) {
        $j('.threesixty').each(function () {
            var config = $j.extend({}, JSON.parse($j(this).attr('data-threesixty-slider')), {drag: true, navigation: false });
            $j(this).ThreeSixty(config);
        });
    }

    // Facet menu
    (function() {
        // hide "-" sign for active facet menu items on manufacturer category page
        $j('.facetapi-facet-korbanek-product-parameters .facetapi-active').text('');

        // toggle facet menu on manufacturer category page
        $j('.facet-menu-toggler').click(function(){
            $j('.facet-menu-toolbar').toggleClass('open');
            $j('.toggled-facet-menu').slideToggle("fast");
        })

        // If there are any active "facet api" filters
        if($j('.facetapi-facet-korbanek-product-parameters .facetapi-active').length > 0) {

            // open facet menu if any filter is active
            $j('.facet-menu-toggler').each(function() {
                $j(this).trigger('click');
            });

            // add "Usun filtry" button
            (function() {
                var removeAllFacetApiFiltersButtonHtml = $j('<div class="remove-filters-button-container"><button type="button" class="btn btn-default brand-background-color brand-border-color remove-all-filters">Wyczyść filtry</button></div>');
                $j('.toggled-facet-menu .panel-body').html(removeAllFacetApiFiltersButtonHtml);
                var newElementsAppended = $j(removeAllFacetApiFiltersButtonHtml).appendTo('.toggled-facet-menu .panel-body');
                newElementsAppended.click(function(){
                    window.location.href = window.location.href.split('?')[0];
                })
            })();
        }
    })();

    // change pagination text from 'of' to 'z';
    (function() {
        $j('.pager .pager-item').each(function() {
            var text = $j(this)[0].textContent;


            if(text.indexOf('of') != -1) {
                var newTxt = text.replace('of','');
                $j(this).html(newTxt);
                $j(this).show();
            }
        })
    })();

    // Nice Select

    $j(document).ready(function() {
        $j('.nice-select').niceSelect();
    });

    // Sort selected TOP NAVIGATION SUBMENUS alphabetically for mobile users
    (function () {
        var menus = [
            '#navbar #Nowe-maszyny-wg-marek',
            '#navbar #Maszyny-rolnicze',
            '#navbar #Maszyny-komunalne',
            '#navbar #Maszyny-budowlane'
        ];

        menus.forEach(function (el) {
            var links = $j(el).find('a').clone();
            var newContainer = document.createElement('div');
            var row = $j(el).find('.row-same-height');
            if (!row || !row.get(0) || !row.get(0).children[0]) {
                return;
            }
            var containerClass = $j(row.get(0).children[0])
                .attr('class')
                .split(' ');
            containerClass.push('sorted-mobile');
            containerClass.forEach(function (cssClass) {
                newContainer.classList.add(cssClass);
            });

            $j(newContainer).appendTo(row);
            // $j(row).append(newContainer);
            var list = document.createElement('ul');
            $j(list).appendTo(newContainer);
            // newContainer.append(list);
            
            links.sort(function (a, b) {
                return a.innerHTML.localeCompare(b.innerHTML);
            });

            var listItems = links.map(function () {
                var listItem = document.createElement('li');
                listItem.appendChild(this);
                return listItem;
            });

            for (var i = 0; i < listItems.length; i++) { 
                list.appendChild(listItems[i]);
            }
        })
    }());


    // Managable sliders
    (function() {

        // "FENDT" type slider
        $fendtTypeSlider = $j('.slider-wrapper-style2 .managable-slider-container');
        $fendtTypeSlider.each(function() {
            var $parentSlider = $j(this),
                parentId = $parentSlider.attr('id'),
                parentConfig = JSON.parse($parentSlider.attr('data-slick'));

            var secoondSliderConfig = $j.extend({},parentConfig, {
                arrows: false,
                rtl: false,
                dots: false,
                vertical: true,
            });

            var thirdSliderConfig = $j.extend({},parentConfig, {
                arrows: false,
                rtl: false,
                dots: false
            });

            var fourthSliderConfig = $j.extend({},parentConfig, {
                arrows: false,
                dots: false,
                fade: true
            });

            var fifthSliderConfig = $j.extend({},parentConfig, {
                arrows: true,
                dots: true
            });


            // Right side slider
            var secondSliderHtml = $parentSlider.clone();
            var $secondSliderFirstItem = $j(secondSliderHtml).find('.slider-style3').last();
            $j(secondSliderHtml).prepend($secondSliderFirstItem);
            secondSliderHtml.attr('id', parentId.replace('-1','-2'));
            secondSliderHtml.attr('data-slick', JSON.stringify(secoondSliderConfig));

            // Slider above main slider
            var $thirdSliderHtml = $parentSlider.clone();
            var thirdSliderFirstItem = $j($thirdSliderHtml).find('.slider-style3').last();
            $j($thirdSliderHtml).prepend(thirdSliderFirstItem);
            var  $thirdSliderSecondItem = $j($thirdSliderHtml).find('.slider-style3').last();
            $j($thirdSliderHtml).prepend($thirdSliderSecondItem);
            $thirdSliderHtml.attr('id', parentId.replace('-1','-3'));
            $thirdSliderHtml.attr('data-slick', JSON.stringify(thirdSliderConfig));

            // Text Slider
            var fourthSliderHtml = $parentSlider.clone();
            fourthSliderHtml.attr('id', parentId.replace('-1','-4'));
            fourthSliderHtml.attr('data-slick', JSON.stringify(fourthSliderConfig));

            // Navigation
            var fifthSliderHtml = $parentSlider.clone();
            fifthSliderHtml.attr('id', parentId.replace('-1','-5'));
            fifthSliderHtml.attr('data-slick', JSON.stringify(fifthSliderConfig));

            // Append all of the above sliders
            // $parentSlider.parent().prepend($thirdSliderHtml);
            // $parentSlider.parent().siblings('.slider-content-right').append(secondSliderHtml);
            // $parentSlider.parent().siblings('.slider-content-right').append(fourthSliderHtml);
            // $parentSlider.parent().parent().siblings('.slider-navigation').append(fifthSliderHtml);
        });

        // Initialize managable sliders
        $j('.managable-slider-wrapper .managable-slider-container').each(function(){
            $j(this).slick();
        });

        var video = $j('#mainSlider video').prop('muted', true);
        video.prop('controls', true);
        $j('.vjs-big-play-button').css('display', 'none');
  

        $j('.join-polaris-club').click(function(e) {
            e.preventDefault();
            $j('.korbanek-form-section').slideToggle();
        })

        $j('.korbanek-polaris-klub select[name="fields[model]"]').on('change', function() {
            if($j(this).val() == 'Inny') {
                var parent = $j(this).closest('.form-group');
                var newElement = '<div class="form-group hidden-label"><label for="fields[model-inny]" class="control-label">Inny Model Pojazdu</label><div class="help-block with-errors"></div><input name="fields[model-inny]" class="form-control" required="" data-error="Podaj Model pojazdu"></div>';
                parent.after(newElement);
            } else {
                $j('label[for="fields[model-inny]"]').parent().remove();
            }
        })

        $j(document).on('click', '#contact-anchor', function (e) { 
            e.preventDefault();
            $j('html, body').animate({
                scrollTop: $j("#footer-contact-form").offset().top - $j('#navbar').outerHeight()
            }, 900);
        })

    })();

    if ('.brand-gallery-container.gallery-mode-slider .row') {
        $j("<div id='preloader'><div id='loader'></div></div>").appendTo("body");
    }


})