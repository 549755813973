$j(window).load(function() {
    "use strict";
   
    if('p[data-scroll-to]') {
        var navItems = $j('p[data-scroll-to]');
        navItems.each(function() {
        	var target = this;
        	$j(this).on('click', function() {
                    $j(window).scrollTo(
                            document.getElementById($j(this).attr('data-scroll-to')), 800,{offset: {top: -80,}} 
                    )	
        	})
        });
    }
});