(function ($) {
    $(document).ready(function () {
        var slides = $('.slickSlider-data');


        if (slides.length > 0) {

            var tslider = new tooltipSlider();
            tslider.initialize();


            $('.slickSlider-data').slick({
                dots: false,
                infinite: false,
                speed: 300,
                responsive: [
                    {
                        breakpoint: 940,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: false,
                        }
                    }
                ]



            }).on('setPosition', function () {
                tslider.setTooltipContainerPosition();
            })
        }
    })

})(jQuery);

(function ($) {
    $(document).on('ready', function () {
        var slides = $('.media-gallery-group .media-gallery-group-videos');

        if (slides.length > 0) {
//
            $('.media-gallery-group .media-gallery-group-videos').slick({
                dots: false,
                infinite: false,
                speed: 1,
                appendArrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                asNavFor: '.control-gallery-group .control-gallery-group-videos',
            });


            $('.control-gallery-group .control-gallery-group-videos').slick({
                speed: 100,
                slidesToShow: 5,
                infinite: false,
                focusOnSelect: true,
                slidesToScroll: 5,
                asNavFor: '.media-gallery-group .media-gallery-group-videos',
                responsive: [
                    {
                        breakpoint: 1010,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }

                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        }
    });

    var machinesSlider = $('.news-article-related-machines-region .view-produkty-highlighted.machines-slider .view-content');

    if (machinesSlider.length > 0) {
        machinesSlider.slick({
            speed: 1000,
            slidesToShow: 1,
            infinite: true,
            focusOnSelect: true,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }

                },
                {
                    breakpoint: 570,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }

                }
            ]
        });
    }

    var machinesSlider = $('.page-strona-domowa .view-produkty-highlighted.machines-slider .view-content');

    if (machinesSlider.length > 0) {
        machinesSlider.slick({
            dots: false,
            infinite: true,
            speed: 1500,
            autoplay: true,
            autoplaySpeed: 1500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 770,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 530,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                }
            ]
        });
    }




})(jQuery);

(function ($) {
    $(document).ready(function () {
        var slides = $('.user-video-gallery');

        if (slides.length > 0) {

            $('.user-video-gallery .slider-slides').slick({
                dots: false,
                infinite: false,
                speed: 1,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                fade: true,
                asNavFor: '.user-video-gallery .slider-tabs',
                responsive: [
                    {
                        breakpoint: 740,
                        settings: {
                            arrows: true,
                        }
                    }
                ]


            });

            $('.user-video-gallery .slider-tabs').slick({
                dots: false,
                infinite: false,
                speed: 100,
                arrows: true,
                adaptiveHeight: true,
                slidesToShow: 4,
                focusOnSelect: true,
                slidesToScroll: 4,
                asNavFor: '.user-video-gallery .slider-slides',
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 1010,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }

                    },
                    {
                        breakpoint: 740,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }

                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        }


        $('.brand-gallery-container.gallery-mode-slider .row').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }

                },
                {
                    breakpoint: 590,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }

                }
            ]
        });
    });
    window.onload = function() {
        $("#preloader").remove();
      };
})(jQuery);
