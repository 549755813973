

$j(document).ready(function(){

    // FOR DEBUG PURPOSE
    // $j('.korbanek-polaris-klub [name="fields[name]"]' ).val('Maciej Ostrowski');
    // $j('.korbanek-polaris-klub [name="fields[email]"]' ).val('test@test.pl');
    // $j('.korbanek-polaris-klub [name="fields[cellPhone]"]' ).val('7732121');
    // $j('.korbanek-polaris-klub [name="fields[name]"]' ).val('Maciej Ostrowski');
    // $j('.korbanek-polaris-klub [name="fields[birthYear]"]' ).val('1985');
    // $j('.korbanek-polaris-klub [name="fields[city]"]' ).val('Świebodzin');
    // $j('.korbanek-polaris-klub [name="fields[street]"]' ).val('Wałowa 60B');
    // $j('.korbanek-polaris-klub [name="fields[zip]"]' ).val('66-200');
    // $j('.korbanek-polaris-klub [name="fields[tShirtSize]"]' ).val('XL');
    // $j('.korbanek-polaris-klub [name="fields[state]"]' ).val('Wielkopolskie');
    // $j('.korbanek-polaris-klub [name="fields[model]"]' ).val('Outlaw 50');
    // $j('.korbanek-polaris-klub [name="fields[vin]"]' ).val('123');
    // $j('.korbanek-polaris-klub [name="fields[howOften]"]' ).val('Kilka razy w roku');
    // $j('.korbanek-polaris-klub [name="fields[licence]"]' ).val('P');
    //
});