
var KorbanekInputFieldsMasking = function() {};
KorbanekInputFieldsMasking.initialize = function(selector, options) {
    $j(selector, this.$form).each(function(idx, e) {
        $j(e).mask(
            $j(e).data('maskPattern'), $j.extend(options, { 
                placeholder: $j(e).attr('data-mask-placeholder')
                    ? $j(e).attr('data-mask-placeholder')
                    : $j(e).attr('placeholder')
                
            })
        );
    });
};

