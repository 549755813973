KorbanekGoogleCaptchaCallback = function() {
    $j('.g-recaptcha').each(function(idx, e) {
        if (!$j(e).attr('id')) {
            $j(e).attr('id', 'google-recaptcha-' + (idx + 1) );
        }
        var captchaId = grecaptcha.render($j(e).attr('id'), {
            sitekey : KorbanekCaptchaCallbackSiteKey,
            callback : function() {
                $j(e).removeClass('is-required');
            }
        });
        $j(e).attr('data-captcha-id', captchaId);
    });
};