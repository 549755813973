(function ($) {
    $(document).on('ready',function () {
        $(".media-gallery-group .media-gallery-group-images").mCustomScrollbar({
            scrollbarPosition: 'outside',
            autoHideScrollbar: false,
        });
        
        $(".thin-vertical-scroll").mCustomScrollbar({
            scrollbarPosition: 'outside',
            autoHideScrollbar: false,
        });
    })
    

})(jQuery);
