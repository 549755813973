$j(document).ready(function() {

    /*
    *    Usuwa duplikaty glownego parametru na stronie listingu produktow oraz produktu
    */

    var machineMainParamElements = $j('.products-search-grid-box .product-param-value, .view-product-view-content-page .page-title .subtitle');
    if(machineMainParamElements.length > 0) {

        machineMainParamElements.each(function() {
            var fieldValue = $j(this).text().match(/(^\d*)+(\D*)+(\d*)/g);
            splittedValue = fieldValue[0].split('-');

            if(fieldValue && splittedValue && splittedValue[1] && splittedValue[0].trim() == splittedValue[1].trim()) {
                $j(this).text($j(this).text().replace(fieldValue, splittedValue[0].trim()));
            }
        })
    }
})