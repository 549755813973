$j = jQuery.noConflict();

window.stickyHeader = function() {};
window.stickyHeader.prototype = {

    selectorToUpdate: null,
    boundryElement: null,
    stickyClass: 'sticky',
    isSticky: false,
    boundryElementTopOffset: null,
    boundryElementHeight: null,
    
    initialize: function(selectorToUpdate, boundryElement) {
        this.selectorToUpdate = selectorToUpdate;
        this.boundryElement = boundryElement;
        this.setBoundryElementTopOffset();
        this.setEvents();
    },
    
    setEvents: function() {
        var that = this;
        $j(window).scroll(function(){
            that.toggleSticky();
        });        

        $j(window).on('resize', function() {
            that.setBoundryElementTopOffset();
        });
    },
   
    setBoundryElementTopOffset: function() {      
        if (!$j(this.boundryElement).length) {
            return;
        }
        
        this.boundryElementTopOffset = $j(this.boundryElement).offset().top;
        // this.boundryElementHeight = $j(this.boundryElement).height();
    },
    
    enableStickyHeader: function() {
        this.isSticky = true;
        $j(this.selectorToUpdate).addClass(this.stickyClass);
        // $j('body').css('margin-top', $j(this.boundryElement).height());    
    },
    
    disableStickyHeader: function() {
        $j(this.selectorToUpdate).removeClass(this.stickyClass);
        this.isSticky = false;   
        // $j('body').css('margin-top', 0);
    },
    
    toggleSticky: function() {
        if($j(window).scrollTop() > this.boundryElementTopOffset) {
            if(!this.isSticky) {
                this.enableStickyHeader();    
            }
        } else {
            if(this.isSticky) {
                this.disableStickyHeader();    
            }   
        }
    }
    
};

$j(document).ready(function(){
    var containerStickyHeader = new stickyHeader().initialize('body', '.nav.navbar-nav' );
})

$j(document).ready(function(){
    $j('#navbar button.navbar-toggle').on('click', function() {
        $j(this).toggleClass('open');
    })
})
