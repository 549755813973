
var KorbanekDropzone = function(wrapperElement, options) {
    
    this.constructor = function() {
        this.wrapperElement = wrapperElement;
        this.$wrapperElement = $j(wrapperElement);
        this.$wrapperElement.data('korbanek-dropzone', this);
        this.dropzone = null;
        this.wrapperId = this.$wrapperElement.attr('id') 
            ? this.$wrapperElement.attr('id')
            : this.assignNewId(wrapperElement);

        this.defaultOptions = {
            url : Drupal.settings.basePath + "kontakt/dropzone?ajax=1",        
            uploadMultiple : true,
            paramName: 'files',
            maxFilesize : 20,
            maxFiles: 10,
            addRemoveLinks : true,
            dictDefaultMessage: 'Umieść pliki zdjęć w tym obszarze.',
            dictFallbackMessage: "Twoja przeglądarka nie obsługuje metody 'przeciągnij i upuść'.",
            dictFallbackText: "Proszę użyć klasycznego formularza, ze względu na wyłączoną obsługę javascript.",
            dictFileTooBig: "Plik jest zbyt duży ({{filesize}}MiB). Maksymalna wielkość: {{maxFilesize}}MiB.",
            dictInvalidFileType: "Niedozwolony typ plików.",
            dictResponseError: "Serwer odpowiedział z kodem {{statusCode}}.",
            dictCancelUpload: 'Anuluj',
            dictCancelUploadConfirmation: "Czy jesteś pewien, że chesz anulować wysyłanie ?",
            dictRemoveFile: 'Usuń',
            dictMaxFilesExceeded: "Nie możesz dodać więcej plików.",
            clickable: '#' + this.wrapperId  + ', #' + this.wrapperId + " .dropzone-clickable",
            //previewsContainer: '#' + this.wrapperId + " .korbanek-dropzone-preview-container"
        };

        this.options = $j.extend( this.defaultOptions, options );

        var maxFilesizeMb = this.$wrapperElement.data('max-filesize-mb');
        if (maxFilesizeMb) {
            this.options.maxFilesize = maxFilesizeMb;
        }

        var isActeptingImages = this.$wrapperElement.data('is-images-dropzone');
        if (isActeptingImages && isActeptingImages !== "false" ) {
            this.options.acceptedFiles = 'image/*';
        }
        
    };
    
    this.isWorking = function() {
        if (!this.dropzone) {
            return false;
        }
        if (this.dropzone.getUploadingFiles().length > 0 
            || this.dropzone.getQueuedFiles().length > 0) {
            return true;
        }
        return false;
    };
    
    this.activate = function() {
        var that = this;
        
        this.options.url += '&validate_image=1'
        
        this.dropzone = new Dropzone("#" + this.wrapperId + ' .dropzone', $j.extend(this.options, {
            init : function() {
                
                this.on('addedfile', function(){
                    that.$wrapperElement.trigger('changed');
                });
                
                this.on('removedfile', function(){
                    that.$wrapperElement.trigger('changed');
                });

                this.on('successmultiple', function(files, jsonResponse) {
                    for (var i = 0; i < files.length; ++i) {
                        if (!that.hasNthFileInResponse(jsonResponse, i)) {
                            continue;
                        } 
                        files[i].korbanekDropzoneResponse = jsonResponse.uploaded_files[i];
                    } 
                });
                
                this.on('success', function(file, jsonResponse) {
                    if (!that.hasNthFileInResponse(jsonResponse, 0)) {
                        return;
                    }
                    file.korbanekDropzoneResponse = jsonResponse.uploaded_files[0];
                });
                
            }
        }));
    };

    
    this.isRequired = function() {
        return !!this.$wrapperElement.data('is-required');
    };
    
    this.removeAllFiles = function(cancelActive) {
        if (!this.dropzone) {
            return false;
        }
        return this.dropzone.removeAllFiles(cancelActive);
    };
    
    this.isEmpty = function() {
        if (!this.getAccepptedFrontendFiles()) {
            return true;
        }
        return this.getAccepptedFrontendFiles().length === 0;
    };
    
    this.hasRejectedFiles = function() {
        if (!this.dropzone) {
            return false;
        }
        if (this.dropzone.getRejectedFiles() 
            && this.dropzone.getRejectedFiles().length > 0) {
            return true;
        }
        
        if ($j('.dz-error', this.$wrapperElement).length > 0) {
            return true;
        }
        
        return false;
    };
    
    this.getAccepptedFrontendFiles = function() {
        if (!this.dropzone) {
            return [];
        }
        return this.dropzone.getAcceptedFiles();
    };
    
    this.getAccepptedBackendFiles = function() {
        if (!this.dropzone) {
            return [];
        }
        var frontendFiles = this.dropzone.getAcceptedFiles();
        var backendFiles = [];
        for (var i = 0; i < frontendFiles.length; ++i) {
            
            if (typeof(frontendFiles[i]) === 'undefined' 
                || typeof(frontendFiles[i].korbanekDropzoneResponse) === 'undefined' 
            ) {
                continue;
            }
            
            backendFiles.push(frontendFiles[i].korbanekDropzoneResponse);
        }
        return backendFiles;
    };
    
    this.hasNthFileInResponse = function(response, n) {
        return this.isValidResponse(response)
            && typeof(response.uploaded_files[n]) !== 'undefined';
    };
    
    
    this.isValidResponse = function(response) {
        return typeof(response) === 'object'
            && typeof(response.uploaded_files) === 'object';
    };
    
       
    this.assignNewId = function(e) { 
        var newId = 'dropzone-' + Math.floor((Math.random() * 10000000) + 1);
        $j(e).attr('id', newId);
        return newId;
    };
    
    
    this.constructor();
};

KorbanekDropzone.initialize = function(selector, options) {
    Dropzone.autoDiscover = false;
    
    $j(selector).each(function(idx, element) {
        var contactForm = new KorbanekDropzone(element, options);
        contactForm.activate();
    });
};

