function changeButtonState() {
    var operation = $j('#edit-operation').val();

    if( $j('.mailstorage_mass:checked').length > 0 && operation !== '0') {
        $j('#mailstorage_mass_submit').removeAttr('disabled');
    } else {
        $j('#mailstorage_mass_submit').attr('disabled','disabled');
    }    
}

$j(document).ready(function(){

    $j('.mailstorage_mass').click(function() {
        changeButtonState();
    });
    
    $j('#edit-operation').change(function() {
        changeButtonState();
    });
    
    $j('#mailstorage_mass_submit').click(function() {
        if (!confirm("Czy na pewno chcesz usunąć?")) {
            return false;
        }
        
        var ids = $j('.mailstorage_mass:checked')
                        .map(function () {return this.value;})
                        .get()
                        .join(",");
                
        $j.ajax({
            type: 'POST',
            url: '/admin/korbanek/subscribers/mass/delete',
            data : {
                ajax: "1",
                ids: ids
            }
        })
        .done(function (data) {
            var status = 'Wystąpił błąd';
    
            if (data === 'Ok') {
                status = 'Usunięte';
                
                $j.each( ids.split(','), function(index, value) {
                    $j('.email-row-' + value).hide();
                });
            }
            
            var html = '';
            html += '<div id="console" class="clearfix">';
            html += '<div class="messages status">';
            html += '<h2 class="element-invisible">Komunikat</h2>';
            html += status;
            html += '</div>';
            html += '</div>';
                
            $j("#content").prepend(html)
            
        });        
        
    });

});