

$j(document).ready(function(){
    
    /* Handles Product Facet Slider Parameter 
     * 
     * Under the hood, it simuluates sliding of two, range:min and range:max sliders.
     * 
     * */
    
    var handler = $j('#korbanek_parameters_slider');
    if (!handler.length) {
        return;
    }
    
    var timeout = 1250;
    
    var minmin = $j('#search-api-ranges-block-slider-view-form-field-product-slider-valuefrom .form-item-range-from input').val();
    var minmax = $j('#search-api-ranges-block-slider-view-form-field-product-slider-valuefrom .form-item-range-to input').val();
    var maxmin = $j('#search-api-ranges-block-slider-view-form-field-product-slider-valueto .form-item-range-from input').val();
    var maxmax = $j('#search-api-ranges-block-slider-view-form-field-product-slider-valueto .form-item-range-to input').val();
    
    if (typeof(minmin) === 'undefined'
        || typeof(minmax) === 'undefined'
        || typeof(maxmin) === 'undefined'
        || typeof(maxmax) === 'undefined' ) {
        $j('.product-parameters-slider').hide();
        return;
    }
    
    var timer = null;
    var uri = new URI(window.location.toString());
    var uriQuery = uri.query(true);
    var unit = handler.data('parameter-unit');
    
    var min = parseInt(typeof(uriQuery['slider_min']) !== 'undefined'
        ? uriQuery['slider_min']
        : minmin);
            
    var max = parseInt(typeof(uriQuery['slider_max']) !== 'undefined'
        ? uriQuery['slider_max']
        : maxmax);
        
    var from = parseInt(typeof(uriQuery['slider_from']) !== 'undefined'
        ? uriQuery['slider_from']
        : min);
    
    var to = parseInt(typeof(uriQuery['slider_to']) !== 'undefined'
        ? uriQuery['slider_to']
        : max);
            
    handler.bootstrapSlider({
        id : 'korbanek_parameters_slider',
        min: parseInt(min), 
        max: parseInt(max),
        range: true, 
        tooltip: 'always', 
        tooltip_split: true,
        slider_handle : 'custom',
        value : [parseInt(from), parseInt(to)],
        formatter: function(val) {
            if (Array.isArray(val)) {
                return val[0] + " : " + val[1] + ' ' + unit;
            } else {
                return val + ' ' + unit;
            }
        }
    }).on('slide', function() {
        
        if (timer !== null) {
            window.clearTimeout(timer);
            timer = null;
        }
        
        timer = window.setTimeout(function() {
            var rangeValue = handler.data('bootstrapSlider').getValue();
            reloadSearchView(rangeValue[0], rangeValue[1]);
        }, timeout); 

    
        function reloadSearchView(from, to) {
            var newUri = new URI(uri);
            
            var params = []; 
            var fParams = [];
            
            for (var i = 0; i < 100; ++i) {
                if ('undefined' === typeof(uriQuery['f[' + i + ']'])) {
                    break;
                }
                var val = uriQuery['f[' + i + ']'];
                
                if (val.indexOf("field_product_slider_value") === 0) {
                    continue;
                }
                
                fParams[i] = val;
            }

            $j.each(uri.search(true), function(i, val) {
                if (val.indexOf("f[") === 0) {
                    return;
                }
                params[i] = val;
            });
            
            fParams = fParams.concat([
                'field_product_slider_value%3Afrom:[' + min  + ' TO ' + to + ']',
                'field_product_slider_value%3Ato:[' + from  + ' TO ' + max + ']'
            ]);
            $j.each(fParams, function(i, val){
                params['f[' + i + ']'] = val;
            });
            
            params['slider_min'] = min;
            params['slider_max'] = max;
            params['slider_from'] = from;
            params['slider_to'] = to;


            newUri = newUri.search(params);
            
            if (!newUri.equals(uri)) {
                window.location = newUri.toString();
            }
        }
    
    
    });
    
})