$j = jQuery.noConflict();

window.simpleMetaStringCounter = function () {};
window.simpleMetaStringCounter.prototype = {
    
    config: null,
    
    initialize: function (config) {
        this.config = config;     
        this.run();
    },  
    
    initEvents: function() {
        var that = this;
        that.performUpdate();
        this.config.element.on('keyup', function() {
            console.log('jestem');
            that.performUpdate();
        })
    },
    
    collectData: function() {
        this.config.label = this.config.labelSelector;
        this.config.labelText = this.config.label.text();
        
        return this;
    },

    performUpdate: function() {
        var elValue = this.count(this.config.element.val()),
            currentVal = '';
    
        if(this.config.type == 'characters') {
            currentVal = elValue.characters
        } else if(this.config.type == 'words') {
            currentVal = elValue.words
        }
        
        var difference = this.config.maxLength - currentVal,
            posNum = (difference < 0) ? difference * -1 : difference; 
    
        this.config.element.closest('.form-group').find('.counter-info').remove();

        if(difference >=  0){
            this.config.label.append('<span class="counter-info success">' + this.config.defaultMessage.replace('%s', '<strong>' + posNum + '</strong>') + '</span>');
        } else {
            this.config.label.append('<span class="counter-info warning">' + this.config.warningMessage.replace('%s', '<strong>' + posNum + '</strong>') + '</span>');
        }
    },
    
    count: function(val) {
        var wom = val.match(/\S+/g);
        
        return {
            characters : val.length,
            words : wom ? wom.length : 0
        };
    },
    
    run: function() {
        if(!this.config.isInitialized) {
            this.collectData();
            this.initEvents();
            this.config.isInitialized = true;
        }
    }
}

$j(document).ready(function () {
    
    function changeInputElementsToTextarea() {
        $j('.simplemeta-meta-form-ajax input[type="text"]').each(function() {
            
            var self = this;
            var elClass = $j(self).attr('class').replace('form-text', 'form-textarea');
            var textbox = $j(document.createElement('textarea'))
                    .attr('class', elClass)
                    .attr('name', $j(self).attr('name'))
                    .attr('id', $j(self).attr('id'))
                    .attr('cols',60)
                    .attr('rows', 5);
            $j(self).before(textbox);
            $j('#' + $j(self).attr('id')).val($j(self).attr('value'));
            $j(self).remove();
        })
    }

    
    function determineIfOpenedOrClosed() {
        var defaultIsOpen = 'no';
        var cookieName = 'is-korbanek-seo-editor-open';
        
        var cookieState = $j.cookie(cookieName) 
            ? $j.cookie(cookieName) 
            : defaultIsOpen;
        
        if(cookieState === 'yes'){
            $j('.simplemeta-meta-form-ajax .form-close').trigger('click');
        }
        
        $j('.simplemeta-meta-form-ajax .form-close').on('click', function() {
            $j.cookie(cookieName, cookieState === 'yes' ? 'no' : 'yes', { expires: 7 });
        });
    }
    
    function initializeCounters() {
        if($j('.simplemeta-meta-form-ajax .form-item-description textarea').length > 0 ){
            new simpleMetaStringCounter().initialize({
                "type": 'characters',
                "element": $j('.simplemeta-meta-form-ajax .form-item-description textarea'),
                "labelSelector": $j('.simplemeta-meta-form-ajax .form-item-description label'),
                "maxLength": 160,
                "warningMessage": 'Uwaga! Przekroczyłeś zalecaną liczbę znaków o %s.',
                "defaultMessage": 'Postaraj się nie przekroczyć %s znaków'
            });
        }
        if($j('.simplemeta-meta-form-ajax .form-item-title textarea').length > 0 ){
            new simpleMetaStringCounter().initialize({
                "type": 'characters',
                "element": $j('.simplemeta-meta-form-ajax .form-item-title textarea'),
                "labelSelector": $j('.simplemeta-meta-form-ajax .form-item-title label'),
                "maxLength": 60,
                "warningMessage": 'Uwaga! Przekroczyłeś zalecaną liczbę znaków o %s.',
                "defaultMessage": 'Postaraj się nie przekroczyć %s znaków'
            });
        }

        if($j('.simplemeta-meta-form-ajax .form-item-keywords textarea').length > 0 ){
            new simpleMetaStringCounter().initialize({
                "type": 'words',
                "element": $j('.simplemeta-meta-form-ajax .form-item-keywords textarea'),
                "labelSelector": $j('.simplemeta-meta-form-ajax .form-item-keywords label'),
                "maxLength": 20,
                "warningMessage": 'Uwaga! Przekroczyłeś zalecaną liczbę słów o %s.',
                "defaultMessage": 'Pozostało %s słow'
            });
        }    
    }
    
    changeInputElementsToTextarea();
    determineIfOpenedOrClosed();
    initializeCounters();
    
});