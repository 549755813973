
$j(document).on('ready', function() {
    
    $j('textarea.has-counter').each(function() {
        var $counter = $j(this);
        
        if ($counter.attr('maxlength') <= 0) {
            return;
        }
        
        $counter.charCounter({
            backgroundColor: "#FFFFFF",
            position: {
                right: 10,
                top: 10
            },
            font: {
                size: 10,
                color: "#a59c8c"
            },
            limit: $counter.attr('maxlength')
        });
    });
    
});


