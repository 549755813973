$j = jQuery.noConflict();

window.stringLimiter = function(){};

window.stringLimiter.prototype = {

    limit: null,
    element: null,

    initialize: function(element, counter) {
        this.setData(element, counter);
        this.setStringLimit();
    },

    setData: function(element, counter) {
        this.element = element;
        
        var inlineInitialization = $j(this.element).attr('data-string-limit');
        this.limit = inlineInitialization ? inlineInitialization : counter;
    },
    

    setStringLimit: function() {
        var string = this.element.innerHTML;
        var stringLength = string.length;
        
        if(stringLength > this.limit) {
            var trimmedString = string.substr(0, this.limit);
            this.element.innerHTML = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + '...';
        } 
    }

}

$j(document).ready(function() {
        var stringLimit = $j('[data-string-limit]');
	if(stringLimit.length > 0){
            stringLimit.each(function() {
                new stringLimiter().initialize(this);
            })
	}	
        
        var homepageNewsArticles = $j('.view-news-homepage-box .item .article-excerpt');
	if(homepageNewsArticles.length > 0){
            homepageNewsArticles.each(function() {
                var newText = $j(this).text();
                $j(this).text(newText);
                new stringLimiter().initialize(this, 150);
            })
	}	
})