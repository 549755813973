$j = jQuery.noConflict();

window.accordionElement = function(){};
window.accordionElement.prototype = {
    
    config: [],
    events: null,
    pseudoTrigger: null,
    
    initialize: function(config) {
        this.config = config;
        if(this.canApply()) {
            this.setupAccordion();
        }
    },
    
    canApply: function() {
        if($j(this.config.triggerEl).length > 0 && $j(this.config.collapseEl).length > 0) {
            return true;
        }
    },
    
    setupAccordion: function() {

        if(this.config.pseudoTrigger) {
            this.addPseudoTrigger();
        }
        
        this.checkDimensions();  
        this.setEvents();
    },

    addPseudoTrigger: function() {
        var newTriggerHtml = '<p class="pseudo-trigger">' + this.config.pseudoTrigger.text + '</p>';
        $j(this.config.triggerEl).prepend(newTriggerHtml);
        $j(this.config.triggerEl).find('.pseudo-trigger').attr('title', this.config.pseudoTrigger.text);
        this.pseudoTrigger = $j(this.config.triggerEl + ' ' + '> .pseudo-trigger');
        this.config.triggerEl = this.pseudoTrigger;
    },
    
    checkDimensions: function() {
        if($j(window).width() + this.countScrollbarWidth() < this.config.collapseBreakpoint) {
            this.showPseudoTrigger();
            this.collapseAccordion();
        } else {
            this.hidePseudoTrigger();
            this.expandAccordion();
        }
    },

    countScrollbarWidth: function () {
        var scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return this.scrollbarWidth;
    },
    
    showPseudoTrigger: function() {
        $j(this.pseudoTrigger).show();
    },
    
    hidePseudoTrigger: function() {
        $j(this.pseudoTrigger).hide();
    },
    
    setEvents: function() {
        if(!this.events) {
            var accordion = this;
            
            $j(this.config.triggerEl).on('click', function(e) {
                e.preventDefault;
                e.stopPropagation();
                
                if($j(window).width() + accordion.scrollbarWidth < accordion.config.collapseBreakpoint) {
                    
                    if($j(accordion.config.collapseEl).attr('data-accordion-collapsed') == 'true') {
                        accordion.expandAccordion();
                    } else {
                        accordion.collapseAccordion();
                    }
                }
            });
            
            $j(window).resize(function() {
                accordion.checkDimensions();
            })
            this.events = true;
        }
    },
    
    collapseAccordion: function() {
        if(this.config.initializator) {
            $j(this.config.initializator).attr('data-accordion-initializator-collapsed', "true");
        }
        $j(this.config.triggerEl).attr('data-accordion-collapsed', "true");
        $j(this.config.collapseEl).attr('data-accordion-collapsed', "true");
        $j(this.config.collapseEl).slideUp();
    },
    
    expandAccordion: function() {
        if(this.config.initializator) {
            $j(this.config.initializator).attr('data-accordion-initializator-collapsed', "false");
        }
        $j(this.config.triggerEl).attr('data-accordion-collapsed', "false");
        $j(this.config.collapseEl).attr('data-accordion-collapsed', "false");
        $j(this.config.collapseEl).slideDown();
    }
}

$j(document).ready(function(){
    
    if($j('.used-products-sort-year').length > 0) {
        $j('.used-products-sort-year').each(function() {
            new accordionElement().initialize({
                initializator: $j(this),
                triggerEl: $j(this).find('.pane-title').get(0),
                collapseEl: $j(this).find('.pane-content').get(0),
                collapseBreakpoint: '99999',
            }); 
        }) 
    }   
    
    if($j('.menu-fassetowe-prawe').length > 0) {
        new accordionElement().initialize({
            triggerEl: $j('.menu-fassetowe-prawe').find('h3').get(0),
            collapseEl: $j('.menu-fassetowe-prawe').find('.pane-content').get(0),
            collapseBreakpoint: '1100',
        }); 
    }   
    
    // News : Recent News 
    if($j('.pane-views-news-recent-block').length > 0) {
        new accordionElement().initialize({
            triggerEl: $j('.pane-views-news-recent-block').find('.pane-title').get(0),
            collapseEl: $j('.pane-views-news-recent-block').find('.pane-content').get(0),
            collapseBreakpoint: '1100',
        }); 
    }   
    
    // News : Related News 
    if($j('.pane-korbanek-news-korbanek-news-related').length > 0) {
        new accordionElement().initialize({
            triggerEl: $j('.pane-korbanek-news-korbanek-news-related').find('.pane-title').get(0),
            collapseEl: $j('.pane-korbanek-news-korbanek-news-related').find('.pane-content').get(0),
            collapseBreakpoint: '1100',
        }); 
    }   
    
    // News Sidebar : Tags 
    if($j('.pane-views-news-tags-block').length > 0) {
        new accordionElement().initialize({
            triggerEl: $j('.pane-views-news-tags-block').find('.pane-title').get(0),
            collapseEl: $j('.pane-views-news-tags-block').find('.pane-content').get(0),
            collapseBreakpoint: '1100',
        }); 
    }   
    
    // News Sidebar : Archive 
    if($j('.pane-views-news-archive-block').length > 0) {
        new accordionElement().initialize({
            triggerEl: $j('.pane-views-news-archive-block').find('.pane-title').get(0),
            collapseEl: $j('.pane-views-news-archive-block').find('.pane-content').get(0),
            collapseBreakpoint: '1100',
        }); 
    }   
    
    if($j('.menu-fassetowe-typy-i-marka').length > 0) {
        new accordionElement().initialize({
            triggerEl: '.menu-fassetowe-typy-i-marka',
            collapseEl: '.menu-fassetowe-typy-i-marka > .panels-flexible-region-inside',
            pseudoTrigger: {
              text: 'FILTRY',
            },
            collapseBreakpoint: '1100',
        });
    }
//    
    if($j('.used-products-search-by-manufacturer').length > 0) {
        new accordionElement().initialize({
            triggerEl: '.used-products-search-by-manufacturer .pane-title',
            collapseEl: '.used-products-search-by-manufacturer .pane-content',
            collapseBreakpoint: '1050',
        });
    }
//    
    if($j('.korbanek-products-used-categories h2').length > 0) {
        new accordionElement().initialize({
            triggerEl: '.korbanek-products-used-categories h2',
            collapseEl: '.korbanek-products-used-categories ul',
            collapseBreakpoint: '1050',
        });
    }


    
     if($j('.menu-level-2').length > 0) {
        $j('.menu-level-2').addClass('yamm');
        $j('.menu-level-2 > .menu.nav').addClass('navbar-nav');
        $j('.menu-level-2 > .navbar-nav').removeClass('menu');
        $j('.menu-level-2 .navbar-nav .expanded').addClass('dropdown wh yamm-fw');
        $j('.products-details-menu .menu-level-2 .navbar-nav .dropdown > a').attr('data-toggle', "dropdown");
        $j('.menu-level-2 .navbar-nav .dropdown > a').attr('aria-expanded', "false");
        $j('.menu-level-2 .navbar-nav .menu.nav').addClass('dropdown-menu');
        $j('.menu-level-2 .navbar-nav .dropdown-menu').removeClass('menu');
    }  
    
    if($j('.products-details-menu .menu-name-menu-product-details').length > 0) {
        new accordionElement().initialize({
            triggerEl: '.products-details-menu .menu-name-menu-product-details',
            collapseEl: '.products-details-menu .menu-name-menu-product-details .navbar-nav',
            pseudoTrigger: {
              text: 'MENU PRODUKTU',
            },
            collapseBreakpoint: '1200',
        });
    }
    
})

/** Toolbar dropdown **/ 

function DropDown(el) {
    this.dd = el;
    this.prependTrigger();
    this.initEvents();
}

DropDown.prototype = {
    initEvents : function() {
        var obj = this;
        obj.dd.on('click', function(event){
                $j(this).toggleClass('active');
                event.stopPropagation();
        });	
    },

    prependTrigger: function() {
        var obj = this.dd;
        $j(obj).prepend('<span class="dropdown-text">' + $j(obj).find('.active-sort').text() + '</span>');
    },
};

$j(function() {
        var dd = new DropDown( $j('.toolbar-dropdown .pane-content') );

        $j(document).click(function() {
                $j('.toolbar-dropdown .pane-content').removeClass('active');
        });
});
