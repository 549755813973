$j = jQuery.noConflict();

window.tooltipSlider = function () {};
window.tooltipSlider.prototype = {
    tooltips: [],
    tooltipElements: [],
    tooltipClosingButton: [],
    tooltipsContainer: [],
    initialize: function () {
        this.collectItems();
        this.setTooltipActions();

    },
    collectItems: function () {
        this.tooltips = $j('.cd-single-point');
        this.tooltipClosingButton = $j('.cd-close-info');
        this.tooltipsContainer = $j('.item-tooltips');
        this.collectTooltips();
    },
    collectTooltips: function () {

        var tSlider = this;
        $j(this.tooltips).each(function () {
            var newTooltip = new imageTooltip();
            tSlider.tooltipElements.push(newTooltip.initialize(this));
        });
    },
    setTooltipActions: function () {
        var that = this;
        this.tooltips.children('a').on('click', function (event) {
            event.preventDefault();

            var selectedPoint = $j(this).parent('li');

            that.setTooltipBoxPosition($j(this), selectedPoint);

            if (selectedPoint.hasClass('is-open')) {
                selectedPoint.removeClass('is-open').addClass('visited');
            } else {
                selectedPoint.addClass('is-open').siblings('.cd-single-point.is-open').removeClass('is-open').addClass('visited');
                if ($j(window).width() < 1310) {
                    var parentEl = selectedPoint.parents('.tooltip-item');
                    if ($j(parentEl).prevAll().length > 0) {
                        var moreInfoOffset = $j(parentEl).width() * $j(parentEl).prevAll().length;
                        var moreInfoEl = $j(selectedPoint.children('.cd-more-info'));
                        moreInfoEl.css('left', 'calc(15px + ' + moreInfoOffset + 'px)');
                    }
                }
            }
        });
        this.tooltipClosingButton.on('click', function (event) {
            event.preventDefault();
            $j(this).parents('.cd-single-point').eq(0).removeClass('is-open').addClass('visited');
        });
    },
    setTooltipContainerPosition: function () {
        var tslider = this;
        $j(this.tooltipsContainer).each(function () {
            var imageWidth = $j(this).prev('.tooltip-item > img').width();
            $j(this).width(imageWidth);
            var containermargin = ($j(this).parent('.tooltip-item').width() - imageWidth) / 2;
            $j(this).css('left', containermargin);
        })
    },
    setTooltipBoxPosition: function (element, parentLi) {
        var boxElement = element.next('.cd-more-info');

        var boxElementTopOffset = $j(boxElement).offset().top;
        var boxElementBottomOffset = boxElementTopOffset + $j(boxElement).outerHeight();
        var boxElementLeftOffset = $j(boxElement).offset().left;
        var boxElementRightOffset = boxElementLeftOffset + $j(boxElement).outerWidth();

        var parentElementOffsetTop = $j(boxElement).closest('.slick-list.draggable').offset().top;
        var parentElementOffsetBottom = parentElementOffsetTop + $j(boxElement).closest('.slick-list.draggable').outerHeight();

        if ($j(window).width() < 1310) {
            var boxTopOffset = ($j(boxElement).closest('.slick-list.draggable').height() - $j(boxElement).outerHeight()) / 2;
            $j(boxElement).css('max-width', $j(boxElement).closest('.slick-list.draggable').width() - 80);
            $j(boxElement).css('top', boxTopOffset);
        }
        ;

        if (!parentLi.hasClass('is-open')) {
            if (boxElementLeftOffset < 0) {
                $j(boxElement).removeClass('cd-left cd-right cd-top cd-bottom');
                $j(boxElement).addClass('cd-right');
            }

            if (boxElementRightOffset > $j(window).width()) {
                $j(boxElement).removeClass('cd-left cd-right cd-top cd-bottom');
                $j(boxElement).addClass('cd-left');
            }

            if (boxElementBottomOffset > parentElementOffsetBottom) {
                $j(boxElement).removeClass('cd-left cd-right cd-top cd-bottom');
                $j(boxElement).addClass('cd-top');
            }

            if (boxElementTopOffset < parentElementOffsetTop) {
                $j(boxElement).removeClass('cd-left cd-right cd-top cd-bottom');
                $j(boxElement).addClass('cd-bottom');
            }
        };
    },
}

$j(document).ready(function() {
    $j('.managable-poi .cd-single-point').children('a').on('click', function(){
        var selectedPoint = $j(this).parent('li');
        if( selectedPoint.hasClass('is-open') ) {
            selectedPoint.removeClass('is-open').addClass('visited');
        } else {
            selectedPoint.addClass('is-open').siblings('.cd-single-point.is-open').removeClass('is-open').addClass('visited');
        }
    });
    //close interest point description
    $j('.managable-poi .cd-close-info').on('click', function(event){
        event.preventDefault();
        $j(this).parents('.cd-single-point').eq(0).removeClass('is-open').addClass('visited');
    });
})

window.imageTooltip = function () {};
window.imageTooltip.prototype = {
    tooltip: null,
    tooltipContainer: null,
    initialize: function (tooltip) {
        this.tooltip = tooltip;
        this.placeTooltip();
    },
    placeTooltip: function () {
        var top = $j(this.tooltip).attr('data-position-vertical') + '%';
        var left = $j(this.tooltip).attr('data-position-horizontal') + '%';
        $j(this.tooltip).css('top', top);
        $j(this.tooltip).css('left', left);
    }

}





