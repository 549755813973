
var KorbanekContactForm = function (formElement, options) {

    KorbanekAbstractForm.call(this, formElement, $j.extend({

    }, options));

};

KorbanekContactForm.prototype = Object.create(KorbanekAbstractForm.prototype);


KorbanekContactForm.initialize = function (formSelector, options) {
    $j(formSelector).each(function (idx, e) {
        var contactForm = new KorbanekContactForm(e, options);
        contactForm.activate();
    });
};

